import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
    transform(value: string): string | undefined {
        if (value !== undefined && value !== null) {
            let lIban: string = value.replaceAll(' ', '');
            lIban = lIban.replace(/(.{4})/g, '$1 ');
            return lIban;
        } else {
            return undefined;
        }
    }
}
