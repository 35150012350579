import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AppError,
  AppErrorDetails,
  InternalError,
} from 'src/app/shared/models/app-errors';
import { BuenoWebApiResult } from 'src/app/shared/models/bueno-api-response';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  private errorSource = new BehaviorSubject(new AppError([{
    code: 'EMPTY_ERROR',
    description: '',
  }]));
  currentError = this.errorSource.asObservable();

  constructor(private translate: TranslateService,
    private httpClient: HttpClient,
    @Inject('BASE_API_URL') private baseApiUrl: string,) {
  }

  show(error: AppError): void {
    this.errorSource.next(error);
  }

  handleError(error: any, customManagedError = false): AppError {
    if (error.error instanceof ErrorEvent) { return this.showInternalError(customManagedError); }
    let apiResult;
    if (error instanceof BuenoWebApiResult) {
      apiResult = error as BuenoWebApiResult;
    } else {
      apiResult = error.error as BuenoWebApiResult;
    }
    if (apiResult !== undefined && apiResult !== null) {
      let errorsDetails: AppErrorDetails[] = [];
      if (apiResult.detailedErrors !== undefined && apiResult.detailedErrors !== null) {
        apiResult.detailedErrors.forEach((x: any) => {
          if (x.description !== null) {
            if (x.description.includes('failed to create account: The following information is incorrect:') && x.errorDetails.includes('identifications_type')) {
              this.translate.get('ERROR_CODES.INVALID_ID').subscribe(message => {
                x.description = message;
              });
            }
            if (x.description.includes('INVALIDPOSTALCODE')) {
              this.translate.get('ERROR_CODES.INVALID_POSTAL_CODE').subscribe(message => {
                x.description = message;
              });
            }
            if(x.description.includes('[SEFIDECRITERR]')) {
              this.translate.get('ERROR_CODES.EXTERNAL_SERVICES').subscribe(message => {
                x.description = message;
                x.code = 'SEFIDECRITERR';
              });
            }
          }
        });
        errorsDetails = apiResult.detailedErrors.map((x:any) => new AppErrorDetails(x.code, x.description));
      }
      if (apiResult !== undefined && apiResult !== null) {
        if (apiResult.detailedErrors !== undefined && apiResult.detailedErrors !== null) {
          apiResult.detailedErrors.forEach((x: any) => {
            if (x.description !== null) {
              if (x.description.includes('failed to create account: The following information is incorrect:') && x.errorDetails.includes('identifications_type')) {
                this.translate.get('ERROR_CODES.INVALID_ID').subscribe(message => {
                  x.description = message;
                });
              }
              if (x.description.includes('INVALIDPOSTALCODE')) {
                this.translate.get('ERROR_CODES.INVALID_POSTAL_CODE').subscribe(message => {
                  x.description = message;
                });
              }
            }
          });
          errorsDetails = apiResult.detailedErrors.map((x:any)=> new AppErrorDetails(x.code, x.description));
        } else if (apiResult.DetailedErrors !== undefined && apiResult.DetailedErrors !== null) {
          errorsDetails = apiResult.DetailedErrors.map(x => new AppErrorDetails(x.Code, x.Description));
        }
      }
      const appError = new AppError(errorsDetails);
      if (!customManagedError) {
        this.show(appError);
      }
      return appError;
    } else {
      return this.showInternalError(customManagedError);
    }
  }

  private showInternalError(customManagedError: boolean): InternalError {
    const internalError = new InternalError();
    if (!customManagedError) {
      this.show(internalError);
    }
    return internalError;
  }
}
