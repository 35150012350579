import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';

@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean): void {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
    constructor(private status: HTTPStatus, private oAuth: OAuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(event => {
                // remove calls to IDP server.
                if (!req.url.includes(environment.identityServerUrl) && !req.url.includes('proxy/ayuware')) {
                  this.status.setHttpStatus(true);
                }
                return event;
            }),
            catchError(error => {
                this.status.setHttpStatus(false);
                if (error.status !== 401 ) {
                    return observableThrowError(error);
                }
                this.oAuth.logOut();
                return observableThrowError(error);
            }),
            finalize(() => {
                this.status.setHttpStatus(false);
            })
        );
    }
}
