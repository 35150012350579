import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'image-checkbox',
  templateUrl: './image-checkbox.component.html',
  styleUrls: ['./image-checkbox.component.scss']
})
export class ImageCheckboxComponent implements AfterViewInit, OnChanges {
  @Input() src!: string;
  @Input() text!: string;
  @Input() checkedValue!: boolean;
  id = Math.random().toString(36).substring(7);
  @Output() checked = new EventEmitter<boolean>();

  toggleCheckbox() {
    this.checkedValue = !this.checkedValue;
    this.checked.emit(this.checkedValue);
  }
  ngAfterViewInit(): void {
    if (this.checkedValue) {
      let checkbox = document.getElementById("myCheckbox-" + this.id);
      if (checkbox) {
        checkbox.setAttribute('checked', 'true');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkedValue']) {
      let checkbox = document.getElementById("myCheckbox-" + this.id);
      if (checkbox) {
        if (this.checkedValue) {
          checkbox.setAttribute('checked', 'true');
        } else {
          checkbox.removeAttribute('checked');
        }
      }
    }
  }
}
