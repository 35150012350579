import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare let seon: any;

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  faTimes = faTimes;
  emailAddress!: string;
  emailAddressBuffer!: string;
  step = 'send-code';
  invalidCode!: boolean;
  successfullyResendCode!: boolean;
  constructor(
    private formBuilderEmail: FormBuilder,
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService
  ) {}

  emailForm = this.formBuilderEmail.group({
    emailAddress: [''
      ,
      {
        validators: [Validators.required, Validators.email],
        updateOn: 'change',
      },
    ],
  });

  emailFormValidationForm = this.formBuilderEmail.group({
    code: ['', { validators: [Validators.required], updateOn: 'change' }],
  });
  showTooManyAttempts = false;
  ngOnInit(): void {
    this.userService.getUserInfo().subscribe((user) => {
      this.emailAddress = user.email;
      this.emailAddressBuffer = user.email;
      this.emailForm.patchValue({
        emailAddress: user.email,
      });
    });
  }

  onOTPCodeCompleted(otpCode:any): void {
    this.emailFormValidationForm.patchValue({
      code: otpCode,
    });
  }

  submitCode(): void {
    const code = this.emailFormValidationForm.value.code;
    if (code) {
      this.userService
        .confirmEmail(code, true)
        .subscribe(
          (res) => {
            // this.step = 'completed';
            localStorage.setItem('showEmailNotification', 'true');
            this.userService.updateUserInfo();
          },
          (err) => {
            this.invalidCode = true;
          }
        );
    }
  }

  resendCode(): void {
    this.emailAddressBuffer = this.emailForm.value.emailAddress!;
    this.userService.resendConfirmationEmailLink(true).subscribe((res) => {
      this.successfullyResendCode = true;
    });
  }

  finalise(): void {
    this.userService.updateUserInfo();
  }
}
