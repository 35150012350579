import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-pin-changed',
  templateUrl: './pin-changed.component.html',
  styleUrls: ['./pin-changed.component.scss']
})
export class PinChangedComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private languageService: LanguageService) { }

  ngOnInit(): void {
    const langQueryParam = this.route.snapshot.queryParamMap.get('lang');
    if (langQueryParam) {
      this.languageService.setLanguage(langQueryParam);
    }
  }

}
