import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'maskedIban'})
export class MaskedIbanPipe implements PipeTransform {
  transform(value: string): string | undefined {
    if (value !== undefined && value !== null) {
      //show only the first 4 and the last 4 digits of the IBAN with the proper spaces
      let lIban: string = value.replace(' ', '');
      lIban = lIban.replace(/(.{4})/g, '$1 ');
      //remove the last space
      lIban = lIban.substring(0, lIban.length - 1);
      //mask the middle digits
      let ibanCollection = lIban.split(' ');
      ibanCollection.forEach((element, index) => {
        if (index !== 0 && index !== ibanCollection.length - 1) {
          ibanCollection[index] = '****';
        }
      });
      lIban = ibanCollection.join(' ');
      return lIban;
    } else {
      return undefined;
    }
  }
}
