<div class="columns is-mobile mb-4 paginator is-multiline" style="width: 100%">
  <div class="column is-narrow" style="display: flex;align-items: center;">
    {{'SHOW:' | translate}}
    <div class="pl-3 dropdown is-up is-active">
      <div class="dropdown-trigger">
        <button class="button" style="min-width: 5rem;" aria-haspopup="true" aria-controls="dropdown-menu"
                (click)="showFilters = !showFilters">
          <span>{{pageSize}}</span>
          <span class="icon is-small" style="width: 100%;text-align: end;">
                      <fa-icon *ngIf="!showFilters" [icon]="faAngleDown"></fa-icon>
                      <fa-icon *ngIf="showFilters" [icon]="faAngleUp"></fa-icon>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu" *ngIf="showFilters">
        <div class="dropdown-content">
          <a class="dropdown-item" *ngFor="let pageSize of pageSizes" (click)="pageSizeChanged(pageSize)">
            {{pageSize}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="column pr-0 mr-0">
    <div class="columns is-mobile pr-0 mr-0" style="place-content: end">
      <div class="column is-narrow">
        <button class="button" [disabled]="selectedPage == 1" (click)="pageChanged(selectedPage - 1)">
                <span class="icon">
                  <fa-icon [icon]="faArrowLeft"></fa-icon>
                </span>
        </button>
      </div>
      <div class="column is-narrow" *ngFor="let page of pagesArray"
           [ngClass]="(page === 1 || page === pages || (page >= selectedPage - 2 && page <= selectedPage + 2))?'showColumn':'hideColumn'">
        <ng-container *ngIf="page === 1 || page === pages || (page >= selectedPage - 1 && page <= selectedPage + 1)">
          <button class="button " [class.is-light]="page === selectedPage" [class.is-primary]="page === selectedPage"
                  (click)="pageChanged(page)">{{page}}</button>
        </ng-container>
        <ng-container *ngIf="page === selectedPage - 2 && page!==1 || page === selectedPage + 2 && page !== pages">
          <p> ... </p>
        </ng-container>
      </div>
      <div class="column is-narrow pr-0 mr-0">
        <button class="button " [disabled]="selectedPage==pages" (click)="pageChanged(selectedPage + 1)">
                <span class="icon">
                  <fa-icon [icon]="faArrowRight"></fa-icon>
                </span>
        </button>
      </div>
    </div>
  </div>
</div>
