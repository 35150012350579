import {Injectable} from '@angular/core';
import {UpcomingPayment} from '../../shared/models/upcoming-payment';

@Injectable( {
  providedIn: 'root'
})
export class UpcomingPaymentsService {
  constructor() {}
  getMockData(): UpcomingPayment[] {
    return [
      {type: 'Electricity', description: 'IBERDROLA', amount: 98.65, currency: 'EUR'},
      {type: 'Alarm', description: 'SECURITAS', amount: 49, currency: 'EUR'},
      {type: 'Phone/Internet', description: 'MOVISTAR', amount: 36.99, currency: 'EUR'}
    ];
  }
}
