import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {

  constructor(
    private httpClient: HttpClient,
    private baseApiService: BaseApiService,
    private oAuth: OAuthService,
    @Inject('BASE_API_URL') private baseApiUrl: string,
  ) { }

  getAutocompleteResults(input: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let url = `${this.baseApiUrl}/proxy/google/getautocompleteresults?input=${input}`;
    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((response: any) => JSON.parse(response)),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }
  getPlaceDetails(placeId: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let url = `${this.baseApiUrl}/proxy/google/getplacedetails?placeId=${placeId}`;
    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((response: any) => JSON.parse(response)),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }
}
