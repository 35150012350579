import { Component, OnInit } from '@angular/core';
import { faLifeRing, faCommentSmile, faEnvelope, faQuestion } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-hero-footer',
  templateUrl: './hero-footer.component.html',
  styleUrls: ['./hero-footer.component.scss']
})
export class HeroFooterComponent {

  faLifeRing = faLifeRing;
  faQuestion = faQuestion;
  faEnvelope = faEnvelope;
  faCommentSmile = faCommentSmile;
  constructor() { }
}
