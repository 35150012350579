<div class="columns is-multiline my-2">
  <div class="column m-auto " *ngFor="let option of options; index as i"
    [ngClass]="{'is-one-quarter': options.length >= 4}">
    <div class="cont-checkbox" (click)="changeValue(option)">
      <input type="radio" [name]="'myRadio-'+identifier" [id]="'myRadio-'+identifier+'-' + i" (change)="changeValue(option.value)"
        [checked]="selectedOption == option" />
      <label [for]="'myRadio-'+identifier+'-' + i">
        <img [src]="option.src" *ngIf="option.src" />
        <span class="cover-checkbox">
          <svg viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
        <div class="info" [ngClass]="{'move_info_label': !option.src}">{{ option.text }}</div>
      </label>
    </div>
  </div>
</div>