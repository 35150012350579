<ng-template [ngIf]="emptyRouter" [ngIfElse]="TopBarTemplate">
  <router-outlet></router-outlet>
  <app-hero-footer *ngIf="!hideFooter"></app-hero-footer>
</ng-template>
<ng-template #TopBarTemplate>
  <app-top-header></app-top-header>
  <div class="progess-wrapper" *ngIf="httpLoadingInProgress | async">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  <div class="container is-fluid app_container slim-material-components"
    [ngClass]="useWhiteBackground?'has-background-white no-side-padding':''">
    <div class="notification is-link is-light mx-4 mt-4 p-0" *ngIf="showNativeAppAdd"
      style="background-color: #292A4930;margin-bottom: 0px!important;">
      <button class="delete" aria-label="delete" (click)="this.closeAppAdd()"></button>
      <div class=" p-4  has-text-centered">
        <p class="title" style="margin-bottom: 0px!important;"> {{ 'NATIVE_APP_ADD.TITLE' | translate }}</p>
        <p class="subtitle m-2"> {{ 'NATIVE_APP_ADD.SUBTITLE' | translate }}</p>
        <button class="button is-primary" (click)="this.redirectToAppStore()">
          <p class="subtitle">{{ 'NATIVE_APP_ADD.INSTALL' | translate }}</p>
        </button>
      </div>
    </div>
    <router-outlet class="app-main-content"></router-outlet>
    <app-hero-footer></app-hero-footer>
  </div>
</ng-template>
<div class="modal" [class.is-active]="httpLoadingInProgress | async">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content has-background-white">
    <div class="box shadow" style="text-align: center;">
      <p style="font-size: 18px;">{{ (downloadInProgress? 'DOWNLOADING': 'LOADINGMODAL') | translate }}</p>
      <br>
      <fa-icon [icon]="faSpinner" spin="true" [size]="'3x'"></fa-icon>
    </div>
  </div>
</div>
<div class="modal" [class.is-active]="showError">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content p-2" style="position: fixed;">
    <article class="message is-warning" *ngFor="let errorDetail of errorsDetails">
      <div class="message-header has-text-centered" style="display: block;">
        {{ 'ERROR_CODES.ERROR' | translate }}
      </div>
      <div class="message-content m-4 has-text-centered">
        <ng-container *ngIf="showErrorTranslation; else noTranslation">
          <p>{{ 'ERROR_CODES.' + errorDetail.code|translate }}</p>
        </ng-container>
        <ng-template #noTranslation>
          <p> {{ errorDetail.description ? errorDetail.description : errorDetail.code}}</p>
        </ng-template>
        <button class="button my-2" *ngIf="!this.enableReload" (click)="showError = false">
          {{ 'ERROR_CODES.CLOSE' | translate }}
        </button>
        <div class="columns" *ngIf="this.enableReload">
          <div class="column">
            <button class="button my-2" (click)="this.reload()">
              {{ 'ERROR_CODES.RELOAD' | translate }}
            </button>
          </div>
          <div class="column">
            <button class="button my-2" (click)="showError = false">
              {{ 'ERROR_CODES.CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </article>
  </div>
</div>
<div class="modal is-active" style="position: fixed;" *ngIf="maintenance">
  <div class="modal-background"></div>
  <div class="modal-content onboarding-modal" [ngClass]="isMobile ? 'p-3' : 'p-6'"
    [ngStyle]="{'max-height': isMobile ? '95%':'','width': isMobile ? '95%':''}">
    <div class="columns is-mobile">
      <div class="column">
        <div class="navbar-item is-size-6 has-dropdown" [class.is-active]="langMenu" style="width: fit-content;">
          <a class="navbar-link is-size-6" (click)="toggleLangMenu()">
            <fa-icon [icon]="faGlobe"></fa-icon>&nbsp;
            {{ language.toUpperCase() }}
          </a>
          <div class="navbar-dropdown" *ngIf="langMenu" (mouseleave)="toggleLangMenu()">
            <a class="navbar-item is-size-6" (click)="setLanguage('EN')">English</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NO')">Norsk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('ES')">Español</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('SE')">Svenska</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('FR')">Français</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DE')">Deutsch</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DA')">Dansk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NL')">Dutch</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="title is-size-4">{{ 'MAINTENANCE.TITLE'|translate }} </p>
      <p class="subtitle is-size-6">{{ 'MAINTENANCE.MESSAGE'|translate }}</p>
      <button class="button is-primary is-medium is-medium mt-5" *ngIf="this.enableReload" (click)="this.reload()">
        {{ 'ERROR_CODES.RELOAD' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="modal" [class.is-active]="showLogoutQuestion">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content p-2" style="position: fixed;">
    <article class="message">
      <div class="message-content p-4 has-text-centered">
        <p class="subtitle m-2"> {{ 'ERROR_CODES.CLOSESESSION' | translate }}</p>
        <button class="button m-2 is-primary" (click)="this.startLogoutTimer()">
          {{ 'CONTINUE' | translate }}
        </button>
        <button class="button m-2 is-danger" (click)="this.logout()">
          {{ 'ERROR_CODES.CLOSE' | translate }}
        </button>
      </div>
    </article>
  </div>
</div>
<div class="modal is-active" style="position: fixed;" *ngIf="authenticated && subscriptionDisabled">
  <div class="modal-background"></div>
  <div class="modal-content onboarding-modal" [ngClass]="isMobile ? 'p-3' : 'p-6'"
    [ngStyle]="{'max-height': isMobile ? '95%':'','width': isMobile ? '95%':''}">
    <div class="columns is-mobile">
      <div class="column">
        <div class="navbar-item is-size-6 has-dropdown" [class.is-active]="langMenu" style="width: fit-content;">
          <a class="navbar-link is-size-6" (click)="toggleLangMenu()">
            <fa-icon [icon]="faGlobe"></fa-icon>&nbsp;
            {{ language.toUpperCase() }}
          </a>
          <div class="navbar-dropdown" *ngIf="langMenu" (mouseleave)="toggleLangMenu()">
            <a class="navbar-item is-size-6" (click)="setLanguage('EN')">English</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NO')">Norsk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('ES')">Español</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('SE')">Svenska</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('FR')">Français</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DE')">Deutsch</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('DA')">Dansk</a>
            <a class="navbar-item is-size-6" (click)="setLanguage('NL')">Dutch</a>
          </div>
        </div>
      </div>
      <div class="column">
        <a *ngIf="authenticated" class="navbar-item" style="text-align: end;padding: 0px;display: block;">
          <p class="has-text-danger">
            <fa-stack (click)="logout()">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon [icon]="faSignOut" [inverse]="true" stackItemSize="1x"></fa-icon>
            </fa-stack>
          </p>
        </a>
      </div>
    </div>
    <div>
      <p class="title is-size-4">{{ 'EXPIRED_SUBSCRIPTION.SUBSCRIPTION_EXPIRED'|translate }} </p>
      <p class="subtitle is-size-6">{{ 'EXPIRED_SUBSCRIPTION.ENDING_SUBTITLE'|translate }}</p>
      <app-subscription-plans [reference]="this" [resubscription]="true"></app-subscription-plans>
    </div>
  </div>
</div>