<nav class="navbar header-bar">
  <div class="columns is-fullwidth" [ngClass]="isMobile?'is-mobile is-multiline': 'main_container_padding'">
    <div class="column has-text-centered" [ngClass]="isMobile?'is-full':'is-one-third has-text-left'"
      style="align-self: center">
      <p class="has-text-weight-bold title has-text-white">
        {{ "CARDS" | translate }}
      </p>
    </div>
    <div class="column has-text-right" *ngIf="!isMobile"
      [ngClass]="isMobile?'is-full':'is-one-third is-offset-one-third'">
      <div class="main-services">
        <div class="columns button-columns" [ngClass]="isMobile?'mobile-main-frame is-multiline':'main-frame'">
          <div class="column " [ngClass]="isMobile?'py-1':'is-narrow'">
            <!--<button class="main-headerbutton" style="float: right" (click)="funding = true" *ngIf="ableToFund">
              <div class="main-content">
                <p class="is-size-6 is-block has-text-primary mb-0 has-text-right">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faCreditCard" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0">{{'CARDS_MODULE.FUND.BUTTON' | translate}}</p>
              </div>
            </button>-->
            <button class="main-headerbutton" style="float: right; background-color: transparent; cursor: none;"
              *ngIf="!isMobile">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="main_container_padding is-centered has-text-centered mb-6 pt-4" *ngIf="loaded">
  <div *ngIf="!funding">
    <div class="mb-4" *ngIf="step!=-1 && !ableToFund">
      <mat-progress-bar class="progressbar" mode="determinate" [value]="step*33==99?100:step*33">
      </mat-progress-bar>
    </div>
    <p>
      <img src="assets/img/mastercard.jpg" alt="Cards" width="15%">
    </p>
    <div *ngIf="step===-1">
      <div style="margin-left:20%!important; margin-right: 20%!important;">
        <p class="subtitle has-text-weight-bold">{{ "CARDS_MODULE.INVALID_REQUIREMENTS.TITLE" |translate}}</p>
        <p class="subtitle has-text-weight-normal mt-4">{{ "CARDS_MODULE.INVALID_REQUIREMENTS.DESCRIPTION" |translate}}</p>
        <button class="button is-primary has-text-centered mt-4" routerLink="/transactions/top-up-bank">
          {{ "NEW_TRANSFER.TOP_ACCOUNT_LABEL" |translate}}
        </button>
      </div>
    </div>
    <div *ngIf="step === 1">
      <p class="subtitle" [innerHTML]="'CARDS_MODULE.STEPS.1.INFO'|translate">
      <p>
        {{ "CARDS_MODULE.STEPS.1.ADDRESS_VERIFICATION"|translate }}
      </p>
      <div class="message is-info mx-6">
        <div class="message-body">
          <p><b>{{'ADDRESS' | translate}} :</b> </p>
          <p>{{user.legalAddress.address1}}, {{user.legalAddress.address2}}</p>
          <p>{{user.legalAddress.postalCode}} {{user.legalAddress.city}}, {{user.legalAddress.countryCode == 'ES' ?
            'Spain': user.legalAddress.countryCode}}
          </p>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-narrow">
          <button class="button is-primary has-text-centered" (click)="createCard()">
            {{ "CARDS_MODULE.STEPS.2.BUTTON"|translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="step === 2 && !ableToFund">
      <p class="subtitle" [innerHTML]="'CARDS_MODULE.STEPS.3.INFO'|translate">
      </p>
    </div>
  </div>
  <div *ngIf="ableToFund">
    <div class="columns" *ngIf="!fundingComplete">
      <div class="column">
        <div *ngIf="number!= undefined">
          <p>
            <img src="assets/img/mastercard.jpg" alt="Cards" width="15%">
          </p>
          <p class="subtitle pb-4">
            {{ "CARDS_MODULE.STATUS"|translate }} <br>
            {{ "CARDS_MODULE.ACCOUNT"|translate }} {{ number }} <br>
          <p class="pb-4">
            {{ "CARDS_MODULE.ORDER_INFO"|translate }}
          </p>
        </div>
        <p [innerHTML]="'CARDS_MODULE.FUND.INFO'|translate">
        </p>
      </div>
      <div class="column">
        <div class="my-4 ">
          <div class="frame1000001832-frame1000001832">
            <span class="frame1000001832-text"><span>{{ 'USER_BALANCE_ACTIONS.BALANCE_LABEL'|translate }}</span></span>
            <div class="frame1000001832-price mb-4">
              <span class="frame1000001832-text2"><span>{{ balance }}</span></span>
              <span class="frame1000001832-text4">€</span>
            </div>
          </div>
          <form [formGroup]="fundForm" qfue>
            <mat-form-field appearance="outline">
              <mat-label> {{ 'SEND_MONEY.AMOUNT_LABEL' | translate }}</mat-label>
              <input type="number" class="has-text-centered" matInput appTwoDecimal [disabled]="disableInputs"
                formControlName="amount" />
              <mat-error *ngIf="fundForm.get('amount')!.hasError('required')"
                [innerHtml]="'SEND_MONEY.AMOUNTREQUIRED' | translate">
              </mat-error>
              <mat-error *ngIf="fundForm.get('amount')!.hasError('invalidAmount')"
                [innerHtml]="'CARDS_MODULE.FUND.VALIDATION' | translate">
              </mat-error>
              <mat-error *ngIf="fundForm.get('amount')!.hasError('min')"
                [innerHtml]="'CARDS_MODULE.FUND.VALIDATION' | translate">
              </mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="columns is-centered mt-4">
          <div class="column is-narrow" *ngIf="!showOtpForm">
            <button class="button is-primary has-text-centered" [disabled]="!fundForm.valid" (click)="fundCard()">
              {{ "CARDS_MODULE.FUND.BUTTON"|translate }}
            </button>
          </div>
          <div class="column is-narrow" *ngIf="showOtpForm">
            <form [formGroup]="confirmCodeForm" (submit)="confirmTransfer()">
              <article class="message is-info">
                <div class="message-body pl-0 pr-0 pt-1 content">
                  <ul>
                    <li>{{ 'MOBILEVERIFICATIONINFO.WAITFORSMS' | translate }}</li>
                    <li>{{ 'MOBILEVERIFICATIONINFO.ENTERCODENEXT' | translate }}</li>
                    <li>{{ 'MOBILEVERIFICATIONINFO.CLICKSUBMIT' | translate }}</li>
                  </ul>
                </div>
              </article>
              <code-input class="code-input" (codeCompleted)="onOTPCodeCompleted($event)" [codeLength]="4"></code-input>
              <div class="mb-3 mt-3" style="text-align: center;">
                <button type="submit" [disabled]="!confirmCodeForm.valid" class="button is-primary">{{ 'SENDCODE'
                  |translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="fundingComplete">
      <p class="subtitle">
        {{ "CARDS_MODULE.FUND.SUCCESS"|translate }}
      </p>
      <div class="columns is-centered">
        <div class="column is-narrow">
          <button class="button is-primary has-text-centered" routerLink="/">
            {{ "PRODUCTS_MODULE.BACK_TO_PREVIOUS_PAGE"|translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading" *ngIf="!loaded">
  <fa-icon [icon]="faSpinner" [spin]="true" [size]="'3x'"></fa-icon>
</div>