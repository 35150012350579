import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  faGlobe,
  faUser,
  faCreditCard,
  faHome,
  faThLarge,
  faLifeRing,
  faCog,
  faPortalExit,
  faExchangeAlt,
  faWallet,
  faHouseUser,
  faReceipt,
  faUserFriends,
  faKey,
  faQuestion,
  faCircle,
  faSignOut,
  faPlus,
  faDownload,
  faFileCheck,
  faAddressBook,
  faHouseChimneyCrack,
  faBars,
} from '@fortawesome/pro-solid-svg-icons';
import { LanguageService } from '../core/services/language.service';
import { OAuthService } from 'angular-oauth2-oidc';
import {Router, RouterEvent} from '@angular/router';
import { AuthService } from '../core/auth.service';
import { UserService } from '../core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent {
  @ViewChild('navBurger', { static: false })
  navBurger!: ElementRef;
  @ViewChild('navMenu', { static: false })
  navMenu!: ElementRef;
  langMenu = false;
  moreMenu = false;
  profileMenu = false;
  moreActionsMenu = false;
  faUser = faUser;
  faThLarge = faThLarge;
  faGlobe = faGlobe;
  faHome = faHome;
  faWallet = faWallet;
  faKey = faKey;
  faCog = faCog;
  faQuestion = faQuestion;
  faCreditCard = faCreditCard;
  faExchangeAlt = faExchangeAlt;
  faLifeRing = faLifeRing;
  faHouseUser = faHouseUser;
  faReceipt = faReceipt;
  faUserFriends = faUserFriends;
  faPortalExit = faPortalExit;
  faSignOut = faSignOut;
  faCircle = faCircle;
  faPlus = faPlus;
  faDownload = faDownload;
  faFileCheck = faFileCheck;
  faAddressBook = faAddressBook;
  faHouseChimneyCrack = faHouseChimneyCrack;
  faBars = faBars;
  kycVerified = false;
  language = 'en';
  authenticated = false;
  username!: string;
  email!: string;
  files: any[] = [];
  filesMenu!: boolean;
  pendingFileCounter!: number;
  maintenance: boolean;
  onboarded!: boolean;
  activeMoreMenu = false;
  constructor(
    private languageService: LanguageService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private auth: OAuthService
  ) {
    this.router.events.subscribe((evt:any) => {
      this.langMenu = false;
      this.profileMenu = false;
      this.moreActionsMenu = false;
      if(evt instanceof RouterEvent){
        this.activeMoreMenu = evt.url.includes("/account");
      }
    });
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage !== null) {
      this.language = selectedLanguage;
    }
    this.languageService.getLanguage().subscribe((lang: string) => {
      this.language = lang;
    });
    this.maintenance = environment.underMaintenance;
    this.authService.isAuthenticated$.subscribe((res) => {
      if (this.authenticated !== res) {
        this.authenticated = res;
        if (this.authenticated) {
          this.userService.getUserInfo().subscribe((userDetails) => {
            if (userDetails !== undefined && userDetails.email !== undefined) {
              this.email = userDetails.email;
              this.kycVerified =
                userDetails.providerUserInfos &&
                userDetails.providerUserInfos.filter((u) => {
                  return u.isVerified;
                }).length === 1;
              this.onboarded = (userDetails.subscriptionsHistory !== null && userDetails.subscriptionsHistory.length > 0)
                && (userDetails.providerUserInfos && userDetails.providerUserInfos.filter(
                  (u) => u.ledgerToken !== null && u.iban !== null).length === 1);
              if (this.kycVerified) {
                // this.getPostBoxItems();
              }
            }
          });
        }
      }
    });
  }
  getPostBoxItems(): void {
    this.userService.getPostBoxItems().subscribe((res) => {
      if (res !== undefined) {
        this.files = res;
        const filesToCheck = this.files.filter((file) => {
          return (
            file.customerConfirmation === true &&
            file.confirmationsLog.length === 0
          );
        });
        this.pendingFileCounter = filesToCheck.length;
      }
    });
  }
  setLanguage(lang: string): void {
    this.language = lang.toLowerCase();
    this.languageService.setLanguage(lang.toLowerCase());
    this.langMenu = false;
    this.profileMenu = false;
    this.moreActionsMenu = false;
    this.filesMenu = false;
  }
  toggleLangMenu(event: any): void {
    this.langMenu = !this.langMenu;
    this.moreActionsMenu = false;
    this.profileMenu = false;
    this.filesMenu = false;
  }
  toggleMoreMenu(event: any): void {
    this.moreMenu = !this.moreMenu;
    this.langMenu = false;
    this.moreActionsMenu = false;
    this.profileMenu = false;
    this.filesMenu = false;
  }
  toggleFilesMenu(event: any): void {
    this.filesMenu = !this.filesMenu;
    this.langMenu = false;
    this.moreActionsMenu = false;
    this.profileMenu = false;
  }

  toggleProfileMenu(): void {
    this.profileMenu = !this.profileMenu;
    this.moreActionsMenu = false;
    this.langMenu = false;
    this.filesMenu = false;
  }

  toggleMoreActionsMenu(): void {
    this.moreActionsMenu = !this.moreActionsMenu;
    this.profileMenu = false;
    this.langMenu = false;
    this.filesMenu = false;
  }
  toggleNavbar(): void {
    if (!this.langMenu && !this.moreActionsMenu) {
      this.navBurger.nativeElement.classList.toggle('is-active');
      this.navMenu.nativeElement.classList.toggle('is-active');
    }
    setTimeout(() => {
      this.langMenu = false;
      this.moreActionsMenu = false;
      this.profileMenu = false;
      this.filesMenu = false;
      this.navBurger.nativeElement.classList.remove('is-active');
      this.navMenu.nativeElement.classList.remove('is-active');
    }, 5000);
  }
  logout(): void {
    this.authService.logout();
  }
  login(): void {
    this.auth.initLoginFlow();
  }

  getUserShortcutName(): string {
    return (
      'SD' ||
      this.username
        .split(' ')
        .map((subName) => subName[0])
        .join('')
    );
  }

}
