import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { LanguageService } from './core/services/language.service';
import { faCircle, faGlobe, faLifeRing, faSignOut, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { authCodeFlowConfig } from './core/auth.config';
import { HTTPStatus } from './core/progress.inteceptor';
import { Observable } from 'rxjs';
import { AppError, AppErrorDetails } from './shared/models/app-errors';
import { BaseApiService } from './core/services/base-api.service';
import { AuthService } from './core/auth.service';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { UserService } from './core/services/user.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/angular-ivy';
import { CookieService } from 'ngx-cookie-service';
import { KycService } from './core/services/kyc.service';
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bueno-frontend';
  token = '';
  faLifeRing = faLifeRing;
  faSpinner = faSpinner;
  faGlobe = faGlobe;
  faCircle = faCircle;
  faSignOut = faSignOut;
  httpLoadingInProgress = new Observable<boolean>();
  showError = false;
  emptyRouter = false;
  error!: AppError;
  errorsDetails: any;
  timeoutCounter: any;
  authenticated = false;
  subscriptionDisabled = false;
  showInvoiceDetails = false;
  isMobile = window.innerWidth <= 770;
  language!: string;
  langMenu = false;
  profileMenu = false;
  moreActionsMenu = false;
  blockSubscriptionButton = false;
  enableReload = false;
  showLogoutQuestion = false;
  maintenance: boolean;
  useWhiteBackground = false;
  paymentForm: FormGroup;
  hideFooter = false;
  showNativeAppAdd = false;
  emptyRouterRoutes = ['/succesfuly-pin-changed', '/404', '/user/register-user'];
  hideFooterRoutes = ['/user/register-user'];
  whiteBackgroundRoutes = ['/onboarding', '/transactions', '/account', '/contacts', '/cards', '/user/forgot-password', '/properties', '/insurance', '/direct-debit'];
  downloadInProgress: boolean = false;
  showErrorTranslation: boolean = false;


  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private authService: AuthService,
    private httpStatus: HTTPStatus,
    private router: Router,
    private route: ActivatedRoute,
    private auth: OAuthService,
    private baseService: BaseApiService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private kycService: KycService,
    private deviceService: DeviceDetectorService
  ) {
    this.paymentForm = this.formBuilder.group({
      period: ['', [Validators.required]]
    });
    localStorage.clear();
    this.processLanguageSettings();
    this.configureCodeFlow();
    this.checkDevice();
    if (environment.production) {
      this.configureSentry();
    }
    this.initializeLanguageService();
    this.maintenance = environment.underMaintenance;
    if (!this.maintenance) {
      this.authService.runInitialLoginSequence().then(() => {
        const latestUrl = localStorage.getItem('currentUrl');
        if (latestUrl?.includes("/?code=")) {
          this.router.navigate(['/home']);
        }
      });
    } else {
      this.enableReload = true;
    }
    this.subscribeToAuthService();
    this.subscribeToHttpStatus();
    this.subscribeToRouterEvents();
  }

  private initializeLanguageService(): void {
    this.languageService.getLanguage().subscribe(lang => {
      this.language = lang;
      localStorage.setItem('selectedLanguage', lang);
      if (this.authenticated) {
        this.userService.changePreferredLanguage(lang).subscribe(() => {
          this.translate.use(lang.replace('nb', 'no'));
        });
      } else {
        this.translate.use(lang.replace('nb', 'no'));
      }
    });
  }

  private subscribeToAuthService(): void {
    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (this.authenticated !== authenticated) {
        this.authenticated = authenticated;
        if (this.authenticated) {
          this.userService.getUserInfo().subscribe(user => {
            if (user) {
              this.setUserInfoInSentryAndMouseflow(user);
              this.route.queryParams.subscribe(params => {
                if (params['kyc']) {
                  this.kycService.setKycPending().subscribe(() => {
                    this.userService.updateUserInfo().subscribe(() => {
                      this.router.navigate(['']);
                    });
                  });
                }
              });
              if (user.preferredLanguage) {
                const lang = user.preferredLanguage.replace('GB', 'en');
                this.setLanguage(lang);
                localStorage.setItem('selectedLanguage', lang);
              }
              this.subscriptionDisabled = this.isSubscriptionDisabled(user.subscriptionsHistory);
            }
          });
        }
      }
    });
  }

  private isSubscriptionDisabled(subscriptionsHistory: any[]): boolean {
    if (subscriptionsHistory.length === 0) {
      return false;
    }
    const activeSubscription = subscriptionsHistory.find(subscription => subscription.isActive);
    if (!activeSubscription) {
      return true;
    }
    const paidUntilDate = new Date(activeSubscription.paidUntil);
    const currentDate = new Date();
    return paidUntilDate < currentDate;
  }

  private subscribeToHttpStatus(): void {
    this.httpStatus.getHttpStatus().subscribe(status => {
      this.httpLoadingInProgress = new Observable<boolean>(observer => {
        this.downloadInProgress = localStorage.getItem('downloadInProgress') === 'true';
        observer.next(status && localStorage.getItem('onboardingStep') === null);
      });
    });
    this.baseService.currentError.subscribe(error => {
      if (error && error.errorsDetails) {
        this.handleErrors(error.errorsDetails);
      }
    });
  }

  private subscribeToRouterEvents(): void {
    this.router.events.subscribe(evt => {
      if (evt instanceof RouterEvent) {
        this.handleRouterEvents(evt.url);
      }
    });
  }

  private handleErrors(errorsDetails: AppErrorDetails[]): void {
    if (errorsDetails.length === 0) {
      errorsDetails = [new AppErrorDetails('CONNECTION_ERROR', '')];
      this.enableReload = true;
    } else if (errorsDetails[0].code === 'SEFIDECRITERR') {
      this.enableReload = true;
    }
    if (errorsDetails[0].code !== 'EMPTY_ERROR') {
      this.errorsDetails = errorsDetails;
      this.checkIfErrorTranslationExists(errorsDetails[0].code);
      this.showError = true;
    }
  }


  checkIfErrorTranslationExists(key: string) {
    let translation = this.translate.instant("ERROR_CODES." + key);
    this.showErrorTranslation = translation !== `ERROR_CODES.${key}`;
  }

  private handleRouterEvents(url: string): void {
    localStorage.setItem('currentUrl', url);
    this.emptyRouter = this.emptyRouterRoutes.some(route => url.includes(route));
    this.hideFooter = this.hideFooterRoutes.some(route => url.includes(route));
    this.useWhiteBackground = this.whiteBackgroundRoutes.some(route => url.includes(route));
    if (url.includes('/onboarding')) {
      this.showNativeAppAdd = false;
    }
  }

  ngOnInit(): void {
    this.startLogoutTimer();
    if (localStorage.getItem('selectedLanguage')) {
      this.language = localStorage.getItem('selectedLanguage')!;
    }
  }

  setLanguage(lang: string): void {
    this.language = lang.toLowerCase();
    this.languageService.setLanguage(lang.toLowerCase());
    this.langMenu = false;
    this.profileMenu = false;
    this.moreActionsMenu = false;
  }

  toggleLangMenu(): void {
    this.langMenu = !this.langMenu;
    this.moreActionsMenu = false;
    this.profileMenu = false;
  }

  logout(): void {
    this.authService.logout();
  }

  startLogoutTimer(): void {
    clearTimeout(this.timeoutCounter);
    this.showLogoutQuestion = false;
    this.timeoutCounter = setTimeout(() => {
      if (this.authenticated) {
        this.askLogout();
      }
    }, 4 * 60000);
  }

  askLogout(): void {
    clearTimeout(this.timeoutCounter);
    this.showLogoutQuestion = true;
    this.timeoutCounter = setTimeout(() => {
      if (this.authenticated) {
        localStorage.clear();
        localStorage.setItem('autoLogout', 'true');
        this.authService.logout();
      }
    }, 1 * 60000);
  }

  reload(): void {
    window.location.reload();
  }

  // Trigger yearly payment using Stripe
  yearlyPayment(): void {
    this.userService.stripeSubscription(environment.subscriptionProducts.annual).subscribe(session => {
      window.location.href = session.url;
    });
  }

  // Trigger monthly payment using Stripe
  monthlyPayment(): void {
    this.userService.stripeSubscription(environment.subscriptionProducts.month).subscribe(session => {
      window.location.href = session.url;
    });
  }

  redirectToAppStore(): void {
    const os = this.deviceService.getDeviceInfo().os;
    const storeUrl = os === 'iOS' ? environment.appStoreUrl : os === 'Android' ? environment.playStoreUrl : null;
    if (storeUrl) {
      setTimeout(() => {
        this.cookieService.set('showNativeAppAdd', 'false', 14);
        window.open(storeUrl, '_blank');
      });
    }
  }

  closeAppAdd(): void {
    this.showNativeAppAdd = false;
    this.cookieService.set('showNativeAppAdd', 'false', 14);
  }

  private configureCodeFlow(): void {
    this.auth.configure(authCodeFlowConfig);
    this.auth.loadDiscoveryDocumentAndTryLogin().then(res => {
      if (res) {
        this.auth.setupAutomaticSilentRefresh();
      }
    });
  }

  private configureSentry(): void {
    Sentry.init({
      dsn: 'https://e352be7e415e4d649697f9e7275cedef@o1222038.ingest.sentry.io/6365646',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
      environment: environment.production ? 'production' : 'development',
      tracesSampleRate: 1.0,
      replaysOnErrorSampleRate: 0.3,
      replaysSessionSampleRate: 0.01,
    });
  }

  private checkDevice(): void {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (this.cookieService.get('showNativeAppAdd') !== 'false') {
      this.showNativeAppAdd = isMobile || isTablet;
    }
  }

  private setUserInfoInSentryAndMouseflow(user: any): void {
    const sentryUser = {
      id: user.id,
      email: user.email,
      username: user.email,
      name: `${user.firstName} ${user.lastName}`,
      language: user.preferredLanguage,
    };
    Sentry.setUser(sentryUser);
    if (environment.production) {
      // @ts-ignore
      window._mfq.push(["setVariable", "id", user.id]);
      // @ts-ignore
      window._mfq.push(["setVariable", "email", user.email]);
    }
  }


  processLanguageSettings(): void {
    this.translate.addLangs(['en', 'no', 'es', 'se', 'fr', 'de', 'da']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    let lang = browserLang?.match(/en|no|nb|es|fr|de|da/)?.toString() ? browserLang.replace('nb', 'no') : 'en';
    if (localStorage.getItem('selectedLanguage')) {
      lang = localStorage.getItem('selectedLanguage')!;
    } else {
      localStorage.setItem('selectedLanguage', lang);
      this.language = lang;
    }
    this.translate.use(lang);
  }

  @HostListener('click')
  handleClick(): void {
    this.startLogoutTimer();
  }
}
