import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {PostalCode} from 'src/app/shared/validators/postal-code';
import {UserService} from 'src/app/core/services/user.service';
import {faHouse, faX} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent implements OnInit {
  properties: any;
  showNewPropertyModal = false;
  autocomplete!: google.maps.places.Autocomplete;
  addressRegex = new RegExp('^[a-zA-Z0-9À-ÿ\u00f1\u00d1 _\'().,\\-\\/]*$');
  amount!: number;
  isMobile = window.innerWidth <= 770;
  faHouse = faHouse;
  faX = faX;
  propertySelected: boolean = false;
  selectedProperty: any;
  addressForm = this.formBuilder.group(
    {
      streetAddress: ['', {
        validators: [Validators.required, Validators.maxLength(40), Validators.pattern(this.addressRegex),],
        updateOn: 'change',
      },],
      address2: ['', {updateOn: 'change'}],
      postalCode: ['', {validators: [Validators.required], updateOn: 'change'}],
      city: ['', {validators: [Validators.required, Validators.maxLength(40)], updateOn: 'change',},],
      state: ['', {validators: [Validators.required], updateOn: 'change'}],
      country: ['', {validators: [Validators.required], updateOn: 'change'}],
    },
    {
      validator: PostalCode('postalCode', 'country'),
    }
  );
  propertyForm = this.formBuilder.group({
    propertyType: [
      '0',
      {validators: [Validators.required], updateOn: 'change'},
    ],
    garden: [
      'false',
      {Validators: [Validators.required], updateOn: 'change'},
    ],
    bedrooms: ['', {validators: [Validators.required], updateOn: 'change'}],
    pool: ['false', {Validators: [Validators.required], updateOn: 'change'}],
    size: ['', {validators: [Validators.required], updateOn: 'change'}],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.getProperties();
  }

  getProperties(): void {
    this.userService.getProperties().subscribe((data) => {
      this.properties = data;
      this.amount = data.length;
    });
  }

  openCreation(): void {
    this.showNewPropertyModal = true;
    setTimeout(() => {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete') as HTMLInputElement,
        {componentRestrictions: {country: 'es'}}
      );
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        if (place && place.address_components) {
          const postalCode = place.address_components.find((x: { types: string | string[]; }) =>
            x.types.includes('postal_code')
          )?.short_name;
          const state = place.address_components.find((x: { types: string | string[]; }) =>
            x.types.includes('administrative_area_level_2')
          )?.long_name;
          const streetAddress = place.name;
          const city = place.vicinity;
          this.addressForm.patchValue({
            country: 'ES',
            postalCode,
            state,
            streetAddress,
            city,
          });
        }
      });
    }, 5);
  }

  // function to get the maximun index of the properties
  getNextIndex(): number {
    let max = 0;
    this.properties.forEach((property: { index: number; }) => {
      if (property.index > max) {
        max = property.index;
      }
    });
    return max + 1;
  }

  submitForm(): void {
    const property = {
      // garden: this.propertyForm.value.garden === 'true' ? true : false,
      // bedrooms: parseInt(this.propertyForm.value.bedrooms),
      // pool: this.propertyForm.value.pool === 'true' ? true : false,
      // size: parseFloat(this.propertyForm.value.size),
      // type: parseInt(this.propertyForm.value.propertyType),
      index: this.getNextIndex(),
      address: {
        countryCode: this.addressForm.value.country,
        postalCode: this.addressForm.value.postalCode,
        state: this.addressForm.value.state,
        address1: this.addressForm.value.streetAddress,
        address2: this.addressForm.value.address2,
        city: this.addressForm.value.city,
      },
    };
    this.userService.addProperty(property).subscribe(() => {
      this.getProperties();
      this.showNewPropertyModal = false;
    });
  }
}
