import { FormGroup } from '@angular/forms';

export function ValidDate(controlName: string, checkDateMustBeFuture: boolean, checkDateMustBePast: boolean): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        const today = new Date();

        if (!control.value) {
            return;
        }
        const selectedDate = control.value;
        // set error on matchingControl if validation fails
        if (checkDateMustBeFuture && selectedDate < today) {
            control.setErrors({ expired: true });
        } else if (checkDateMustBePast && selectedDate > today) {
            control.setErrors({ expired: true });
        } else {
            control.setErrors(null);
        }
    };
}
