import { TransactionBeneficiary } from './transaction-beneficiary';
import { CardAcceptor } from './transaction-card-acceptor';
import { TransactionSender } from './transaction-sender';

export class Transaction {
    token!: string;
    source!: string;
    action!: string;
    type!: string;
    status!: string;
    channel!: string;
    amount!: number;
    // tslint:disable-next-line:variable-name
    currency_code!: string;
    // tslint:disable-next-line:variable-name
    currency_conversion!: CurrencyConversion;
    // tslint:disable-next-line:variable-name
    short_reference!: string;
    // tslint:disable-next-line:variable-name
    created_at!: string;
    // tslint:disable-next-line:variable-name
    modified_at!: string;
    // tslint:disable-next-line:variable-name
    transfer_type!: string;
    reason!: string;
    reference!: string;
    beneficiary!: TransactionBeneficiary;
    sender!: TransactionSender;
    // tslint:disable-next-line:variable-name
    card_acceptor!: CardAcceptor;
    referenceId: any;
    description: any;
    payerName: any;
    beneficiaryName: any;
    currencyCode: any;
    actualBalanceAfterMovement: any;
    beneficiaryIban!: string;
    payerIban!: string;
}

export class CurrencyConversion {
    // tslint:disable-next-line:variable-name
    conversion_rate!: string;
    // tslint:disable-next-line:variable-name
    original_amount!: string;
    // tslint:disable-next-line:variable-name
    original_currency_code!: string;
}
