import {Component, Input, OnInit} from '@angular/core';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from '../../core/services/user.service';
import {PostalCode} from 'src/app/shared/validators/postal-code';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input() residentialAddress!: any;
  @Input() userSettings!: boolean;
  faTimes = faTimes;
  addressRegex = new RegExp('^[a-zA-Z0-9À-ÿ\u00f1\u00d1 _\'().,\\-\\/]*$');
  showPropertyForm!: boolean;
  showSpanishMessage!: boolean;
  showFields!: boolean;
  editMode!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService
  ) {
  }

  addressForm = this.formBuilder.group(
    {
      streetName: [''
        ,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.addressRegex),
          ],
          updateOn: 'change',
        },
      ],
      streetNumber: [''
        ,
        {validators: [Validators.required], updateOn: 'change'},
      ],
      blockNumber: ['', {updateOn: 'change'}],
      floorNumber: ['', {updateOn: 'change'}],
      doorNumber: ['', {updateOn: 'change'}],
      city: ['', {validators: [Validators.required], updateOn: 'change'}],
      postalCode: ['', {validators: [Validators.required], updateOn: 'change'}],
      state: ['', {validators: [Validators.required], updateOn: 'change'}],
      country: ['', {validators: [Validators.required], updateOn: 'change'}],
    },
    {
      validator: PostalCode('postalCode', 'country'),
    }
  );

  propertyForm = this.formBuilder.group({
    propertyType: [
      '0',
      {validators: [Validators.required], updateOn: 'change'},
    ],
    garden: [
      'false',
      {Validators: [Validators.required], updateOn: 'change'},
    ],
    bedrooms: ['', {Validators: [Validators.required], updateOn: 'change'}],
    pool: ['false', {Validators: [Validators.required], updateOn: 'change'}],
    size: ['', {Validators: [Validators.required], updateOn: 'change'}],
  });

  ngOnInit(): void {
    if (this.residentialAddress) {
      this.editMode = true;
      this.addressForm.patchValue({
        streetName: this.residentialAddress.nameOfStreet,
        streetNumber: this.residentialAddress.streetNumber,
        blockNumber: this.residentialAddress.blockNumber,
        floorNumber: this.residentialAddress.floorNumber,
        doorNumber: this.residentialAddress.doorNumber,
        country: this.residentialAddress.countryCode,
        postalCode: this.residentialAddress.postalCode,
        state: this.residentialAddress.state,
        city: this.residentialAddress.city,
      });
      if (this.residentialAddress.countryCode === 'ES') {
        this.showPropertyForm = true;
      }
      this.showFields = true;
    }
  }

  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.showPropertyForm = false;
    const country = place.address_components?.find((x: any) =>
      x.types.includes('country')
    )?.short_name;
    if (country) {
      if (country === 'ES') {
        this.showPropertyForm = true;
        const postalCode = place.address_components?.find((x: any) =>
          x.types.includes('postal_code')
        )?.short_name;
        const state =
          country +
          '-' +
          place.address_components?.find((x: any) =>
            x.types.includes('administrative_area_level_2')
          )?.short_name;
        const streetName = place.address_components?.find((x: any) =>
          x.types.includes('route')
        )?.long_name;
        const streetNumber = place.address_components?.find((x: any) =>
          x.types.includes('street_number')
        )?.short_name;
        const city = place.address_components?.find((x: any) =>
          x.types.includes('locality')
        )?.short_name;
        this.addressForm.patchValue({
          country: country ? country : '',
          postalCode: postalCode ? postalCode : '',
          state: state ? state : '',
          streetName: streetName ? streetName : '',
          streetNumber: streetNumber ? streetNumber : '',
          city: city ? city : '',
        });
      } else if (country === 'GB') {
        const postalCode = place.address_components?.find((x: any) =>
          x.types.includes('postal_code')
        )?.short_name;
        const state = place.address_components?.find((x: any) =>
          x.types.includes('administrative_area_level_2')
        )?.long_name;
        const streetName =
          place.address_components?.find((x: any) =>
            x.types.includes('street_number')
          )?.short_name +
          ' ' +
          place.address_components?.find((x: any) => x.types.includes('route'))
            ?.long_name;
        const streetNumber = place.address_components?.find((x: any) =>
          x.types.includes('street_number')
        )?.short_name;
        const city = place.address_components?.find((x: any) =>
          x.types.includes('postal_town')
        )?.short_name;
        this.addressForm.patchValue({
          country: country ? country : '',
          postalCode: postalCode ? postalCode : '',
          state: state ? state : '',
          streetName: streetName ? streetName : '',
          streetNumber: streetNumber ? streetNumber : '',
          city: city ? city : '',
        });
      } else {
        const postalCode = place.address_components?.find((x: any) => x.types.includes('postal_code'))?.short_name;
        const state = place.address_components?.find((x: any) => x.types.includes('administrative_area_level_1'))?.long_name;
        const streetName = place.address_components?.find((x: any) => x.types.includes('route'))?.long_name;
        const streetNumber = place.address_components?.find((x: any) => x.types.includes('street_number'))?.short_name;
        let city = place.address_components?.find((x: any) => x.types.includes('postal_town'))?.short_name;
        if (city === undefined) {
          city = place.address_components?.find((x: any) => x.types.includes('locality'))?.short_name;
        }

        this.addressForm.patchValue({
          country: country ? country : '',
          postalCode: postalCode ? postalCode : '',
          state: state ? state : '',
          streetName: streetName ? streetName : '',
          streetNumber: streetNumber ? streetNumber : '',
          city: city ? city : '',
        }, {emitEvent: true});
      }
      this.showFields = true;
      /// force validation
      /// force validation
      this.addressForm.controls['streetName'].markAsTouched();
      this.addressForm.controls['streetNumber'].markAsTouched();
      this.addressForm.controls['city'].markAsTouched();
      this.addressForm.controls['postalCode'].markAsTouched();
      this.addressForm.controls['state'].markAsTouched();
      this.addressForm.controls['country'].markAsTouched();
    }
  }

  getStreetNumber(address: any): any {
    throw new Error('Method not implemented.');
  }

  getStreetName(address: any): any {
    throw new Error('Method not implemented.');
  }

  submitForm(): void {
    if (this.addressForm.value.postalCode) { // Additional check to avoid unexpected behavior after pressing ENTER
      const address = {
        address1: `${this.addressForm.value.streetName} ${this.addressForm.value.streetNumber}`,
        address2: `${this.addressForm.value.blockNumber ?? ''} ${this.addressForm.value.floorNumber ?? ''} ${this.addressForm.value.doorNumber ?? ''}`,
        city: this.addressForm.value.city,
        state: this.addressForm.value.state,
        countryCode: this.addressForm.value.country,
        postalCode: this.addressForm.value.postalCode,
        province: this.addressForm.value.state,
        nameOfStreet: this.addressForm.value.streetName,
        streetNumber: this.addressForm.value.streetNumber?.toString(),
        blockNumber: this.addressForm.value.blockNumber?.toString(),
        floorNumber: this.addressForm.value.floorNumber?.toString(),
        doorNumber: this.addressForm.value.doorNumber?.toString(),
      };
      this.userService.setAddress(address, address.countryCode !== 'ES').subscribe(() => {
        if (this.editMode) {
          localStorage.setItem('lastEditedStep', 'ResidentialAddress');
        }
        if (address.countryCode === 'ES' && !this.userSettings) {
          // this.showSpanishMessage = true;
          this.userService.setLegalAddress(address).subscribe(() => {
            if (!this.userSettings) {
              this.router.navigate(['']);
            }
          });
        } else {
          if (!this.userSettings) {
            this.router.navigate(['']);
          }
        }
      });
    }
  }

}
