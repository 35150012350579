import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {faAngleDown, faAngleUp, faArrowLeft, faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges, OnInit {
  @Input() totalItems: any;
  @Input() reference: any;
  selectedPage: any = 1;
  pageSize: any = 20;
  pageSizes: any = [5, 10, 15, 20];
  pages: number = 0;
  pagesArray: any = [];
  showFilters: boolean = false;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faAngleDown = faAngleDown;
  protected readonly faAngleUp = faAngleUp;

  constructor() {
  }

  ngOnInit() {
    this.pageSize = this.reference.pageSize;
    if (this.totalItems !== undefined) {
      this.pages = Math.ceil(this.totalItems / this.pageSize);
      this.pagesArray = Array(this.pages).fill(0).map((x, i) => i + 1);
    }
  }

  ngOnChanges(changes: any) {
    if (this.totalItems !== undefined) {
      this.pages = Math.ceil(this.totalItems / this.pageSize);
      this.pagesArray = Array(this.pages).fill(0).map((x, i) => i + 1);
    }
  }

  pageSizeChanged(pageSize: any) {
    this.pageSize = pageSize;
    this.pages = Math.ceil(this.totalItems / this.pageSize);
    this.pagesArray = Array(this.pages).fill(0).map((x, i) => i + 1);
    this.pageChanged(1);
  }

  pageChanged(number: number) {
    let event: PageEvent = new PageEvent();
    this.selectedPage = number;
    event.pageIndex = number - 1;
    event.pageSize = this.pageSize;
    event.length = this.totalItems;
    this.reference.pageChanged(event);
  }
}
