<div class="card mb-5">
  <div class="card-image">
    <figure class="image">
      <img src="/assets/img/house.jpg" style="max-height: 20rem;object-fit: cover" alt="house">
    </figure>
  </div>
  <div class="card-content">
    <div class="content">
      <div class="columns">
        <div class="column is-four-fifths" style="align-self: center" [ngClass]="editionMode?'edition-padding':''">
          <form [formGroup]="addressForm" *ngIf="editionMode">
            <div class="columns pv-0">
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.CITY' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="city"/>
                  <mat-error *ngIf="addressForm.get('city')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.CITY_REQUIRED' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.STREETADDRESS' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="streetName"/>
                  <mat-error *ngIf="addressForm.get('streetName')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.STREETADDRESS_REQUIRED' | translate">
                  </mat-error>
                  <mat-error *ngIf="addressForm.get('streetName')!.hasError('maxlength')"
                             [innerHtml]="'VALIDATIONMESSAGES.MAXLENGTH40GENERIC' | translate">
                  </mat-error>
                  <mat-error *ngIf="addressForm.get('streetName')!.hasError('pattern')"
                             [innerHtml]="'VALIDATIONMESSAGES.ADDRESS_PATTERN' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="columns pv-0">
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.STREETNUMBER' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="streetNumber"/>
                  <mat-error *ngIf="addressForm.get('streetNumber')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.STREETNUMBER_REQUIRED' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.POSTALCODE' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="postalCode"/>
                  <mat-error *ngIf="addressForm.get('postalCode')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.POSTALCODE_REQUIRED' | translate">
                  </mat-error>
                  <mat-error *ngIf="addressForm.get('postalCode')!.hasError('invalidPostalCode')"
                             [innerHtml]="'VALIDATIONMESSAGES.PROPERTYPOSTALCODEGENERIC' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="columns pv-0">
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.BLOCKNUMBER' | translate }} </mat-label>
                  <input type="number" appFormInput matInput formControlName="blockNumber"/>
                </mat-form-field>
              </div>
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.FLOORNUMBER' | translate }} </mat-label>
                  <input type="number" appFormInput matInput formControlName="floorNumber"/>
                </mat-form-field>
              </div>
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.DOORNUMBER' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="doorNumber"/>
                </mat-form-field>
              </div>
            </div>
            <div class="columns pv-0">
              <div class="column pb-0">
                <mat-form-field appearance="outline" *ngIf="addressForm.get('country')?.value !== 'ES'">
                  <mat-label> {{ 'PROFILEDATA.STATE' | translate }} </mat-label>
                  <input type="text" appFormInput matInput formControlName="state"/>
                  <mat-error *ngIf="addressForm.get('state')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.STATE_REQUIRED' | translate">
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="addressForm.get('country')?.value === 'ES'">
                  <mat-label> {{ 'PROFILEDATA.STATE' | translate }} </mat-label>
                  <mat-select formControlName="state">
                    <mat-option value="ES-VI">Álava/Araba</mat-option>
                    <mat-option value="ES-AB">Albacete</mat-option>
                    <mat-option value="ES-A">Alicante</mat-option>
                    <mat-option value="ES-AL">Almería</mat-option>
                    <mat-option value="ES-O">Asturias</mat-option>
                    <mat-option value="ES-AV">Ávila</mat-option>
                    <mat-option value="ES-BA">Badajoz</mat-option>
                    <mat-option value="ES-PM">Baleares</mat-option>
                    <mat-option value="ES-B">Barcelona</mat-option>
                    <mat-option value="ES-BU">Burgos</mat-option>
                    <mat-option value="ES-CC">Cáceres</mat-option>
                    <mat-option value="ES-CA">Cádiz</mat-option>
                    <mat-option value="ES-S">Cantabria</mat-option>
                    <mat-option value="ES-CS">Castellón</mat-option>
                    <mat-option value="ES-CE">Ceuta</mat-option>
                    <mat-option value="ES-CR">Ciudad Real</mat-option>
                    <mat-option value="ES-CO">Córdoba</mat-option>
                    <mat-option value="ES-CU">Cuenca</mat-option>
                    <mat-option value="ES-GI">Gerona/Girona</mat-option>
                    <mat-option value="ES-GR">Granada</mat-option>
                    <mat-option value="ES-GU">Guadalajara</mat-option>
                    <mat-option value="ES-SS">Guipúzcoa/Gipuzkoa</mat-option>
                    <mat-option value="ES-H">Huelva</mat-option>
                    <mat-option value="ES-HU">Huesca</mat-option>
                    <mat-option value="ES-J">Jaén</mat-option>
                    <mat-option value="ES-C">La Coruña/A Coruña</mat-option>
                    <mat-option value="ES-LO">La Rioja</mat-option>
                    <mat-option value="ES-GC">Las Palmas</mat-option>
                    <mat-option value="ES-LE">León</mat-option>
                    <mat-option value="ES-L">Lérida/Lleida</mat-option>
                    <mat-option value="ES-LU">Lugo</mat-option>
                    <mat-option value="ES-M">Madrid</mat-option>
                    <mat-option value="ES-MA">Málaga</mat-option>
                    <mat-option value="ES-ML">Melilla</mat-option>
                    <mat-option value="ES-MU">Murcia</mat-option>
                    <mat-option value="ES-NA">Navarra</mat-option>
                    <mat-option value="ES-OR">Orense/Ourense</mat-option>
                    <mat-option value="ES-P">Palencia</mat-option>
                    <mat-option value="ES-PO">Pontevedra</mat-option>
                    <mat-option value="ES-SA">Salamanca</mat-option>
                    <mat-option value="ES-SG">Segovia</mat-option>
                    <mat-option value="ES-SE">Sevilla</mat-option>
                    <mat-option value="ES-SO">Soria</mat-option>
                    <mat-option value="ES-T">Tarragona</mat-option>
                    <mat-option value="ES-TF">Tenerife</mat-option>
                    <mat-option value="ES-TE">Teruel</mat-option>
                    <mat-option value="ES-TO">Toledo</mat-option>
                    <mat-option value="ES-V">Valencia</mat-option>
                    <mat-option value="ES-VA">Valladolid</mat-option>
                    <mat-option value="ES-BI">Vizcaya/Bizkaia</mat-option>
                    <mat-option value="ES-ZA">Zamora</mat-option>
                    <mat-option value="ES-Z">Zaragoza</mat-option>
                  </mat-select>
                  <mat-error *ngIf="addressForm.get('state')!.hasError('required')"
                             [innerHtml]="'VALIDATIONMESSAGES.STATE_REQUIRED' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="column pb-0">
                <mat-form-field appearance="outline">
                  <mat-label>{{'PROFILEDATA.COUNTRY' | translate}}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option value="AT">{{'COUNTRIES.AUSTRIA' | translate}}</mat-option>
                    <mat-option value="BE">{{'COUNTRIES.BELGIUM' | translate}}</mat-option>
                    <mat-option value="BG">{{'COUNTRIES.BULGARIA' | translate}}</mat-option>
                    <mat-option value="HR">{{'COUNTRIES.CROATIA' | translate}}</mat-option>
                    <mat-option value="CY">{{'COUNTRIES.CYPRUS' | translate}}</mat-option>
                    <mat-option value="CZ">{{'COUNTRIES.CZECH' | translate}}</mat-option>
                    <mat-option value="DK">{{'COUNTRIES.DENMARK' | translate}}</mat-option>
                    <mat-option value="EE">{{'COUNTRIES.ESTONIA' | translate}}</mat-option>
                    <mat-option value="FI">{{'COUNTRIES.FINLAND' | translate}}</mat-option>
                    <mat-option value="FR">{{'COUNTRIES.FRANCE' | translate}}</mat-option>
                    <mat-option value="DE">{{'COUNTRIES.GERMANY' | translate}}</mat-option>
                    <mat-option value="GR">{{'COUNTRIES.GREECE' | translate}}</mat-option>
                    <mat-option value="HU">{{'COUNTRIES.HUNGARY' | translate}}</mat-option>
                    <mat-option value="IS">{{'COUNTRIES.ICELAND' | translate}}</mat-option>
                    <mat-option value="IE">{{'COUNTRIES.IRELAND' | translate}}</mat-option>
                    <mat-option value="IT">{{'COUNTRIES.ITALY' | translate}}</mat-option>
                    <mat-option value="LV">{{'COUNTRIES.LATVIA' | translate}}</mat-option>
                    <mat-option value="LI">{{'COUNTRIES.LIECHTENSTEIN' | translate}}</mat-option>
                    <mat-option value="LT">{{'COUNTRIES.LITHUANIA' | translate}}</mat-option>
                    <mat-option value="LU">{{'COUNTRIES.LUXEMBOURG' | translate}}</mat-option>
                    <mat-option value="MT">{{'COUNTRIES.MALTA' | translate}}</mat-option>
                    <mat-option value="NL">{{'COUNTRIES.NETHERLANDS' | translate}}</mat-option>
                    <mat-option value="NO">{{'COUNTRIES.NORWAY' | translate}}</mat-option>
                    <mat-option value="PL">{{'COUNTRIES.POLAND' | translate}}</mat-option>
                    <mat-option value="PT">{{'COUNTRIES.PORTUGAL' | translate}}</mat-option>
                    <mat-option value="RO">{{'COUNTRIES.ROMANIA' | translate}}</mat-option>
                    <mat-option value="SK">{{'COUNTRIES.SLOVAKIA' | translate}}</mat-option>
                    <mat-option value="SI">{{'COUNTRIES.SLOVENIA' | translate}}</mat-option>
                    <mat-option value="ES">{{'COUNTRIES.SPAIN' | translate}}</mat-option>
                    <mat-option value="SE">{{'COUNTRIES.SWEDEN' | translate}}</mat-option>
                    <mat-option value="CH">{{'COUNTRIES.SWITZERLAND' | translate}}</mat-option>
                    <mat-option value="GB">{{'COUNTRIES.UNITEDKINGDOM' | translate}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="addressForm.get('country')!.hasError('required')"
                             [innerHTML]="'ERROR_CODES.COUNTRY_IS_REQUIRED' | translate">
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
          <div *ngIf="!editionMode" style="color: #8492A5">
            <fa-icon [icon]="faLocationDot"></fa-icon>
            <span>  {{address}}</span>
          </div>
        </div>
        <div class="column buttons-line" *ngIf="!editionMode">
          <a class="main-icon-button m-3" (click)="enableEdition()" matTooltip="Edit property">
            <fa-stack>
              <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
              <fa-icon [icon]="faPenToSquare" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
            </fa-stack>
          </a>
          <a class="m-3" (click)="deleteProperty(property)" matTooltip="Remove property"
             [ngClass]="!canDelete?'disabled':'red-icon-button'">
            <fa-stack>
              <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
              <fa-icon [icon]="faTrash" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
            </fa-stack>
          </a>
        </div>
        <div class="column" [ngClass]="isMobile?'buttons-line': 'buttons-column'" *ngIf="editionMode">
          <a class="main-icon-button m-3" (click)="updateProperty()" matTooltip="Save">
            <fa-stack>
              <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
              <fa-icon [icon]="faCheck" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
            </fa-stack>
          </a>
          <a class="m-3" (click)="disableEdition()" matTooltip="Cancel"
             [ngClass]="!canDelete?'disabled':'red-icon-button'">
            <fa-stack>
              <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
              <fa-icon [icon]="faXmark" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
            </fa-stack>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
