<div class="hero-foot is-fixed-bottom p-4 footer">
  <div class="container">
    <div class="columns  is-mobile is-vcentered is-centered">
      <div class="column is-narrow has-text-centered">
        <a href="https://getbueno.com/help/" target="_blank">
          <fa-icon [icon]="faLifeRing" class="mr-1"></fa-icon>
          {{ 'FOOTER.HELP_CENTER' | translate}}
        </a>
      </div>
    </div>
  </div>
</div>
