import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from 'src/app/core/services/user.service';
import { UserInfo } from 'src/app/models/user-info';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrl: './user-preferences.component.scss'
})
export class UserPreferencesComponent {
  @Input() settingsReference!: any;
  @Input() userSettings!: boolean;
  faArrowLeft = faArrowLeft;
  isMobile = window.innerWidth <= 770;

  preferencesForm = this.formBuilder.group({
    insurance: [false],
    homeAlarm: [false],
    nonResidentTax: [false],
    electricity: [false],
    banking: [false]
  });
  user!: UserInfo;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.userService.getUserInfo().subscribe((userInfo) => {
      this.user = userInfo;
      const preferences = userInfo.interest || {};
      this.preferencesForm.patchValue(preferences);
      this.preferencesForm.markAsPristine();
    });
  }


  insuranceCheckedChange(checked: any) {
    this.preferencesForm.patchValue({ insurance: checked });
  }
  homeAlarmCheckedChange(checked: any) {
    this.preferencesForm.patchValue({ homeAlarm: checked });
  }
  nonResidentTaxCheckedChange(checked: any) {
    this.preferencesForm.patchValue({ nonResidentTax: checked });
  }
  electricityCheckedChange(checked: any) {
    this.preferencesForm.patchValue({ electricity: checked });
  }
  savePreferences() {
    if (this.user.providerUserInfos && this.user.providerUserInfos.filter(
      (u) => u.ledgerToken !== null && u.iban !== null).length === 1){
        this.savePreferencesWithBanking();
      }else{
        this.savePreferencesWithoutBanking();
      }
  }
  savePreferencesWithBanking() {
    let preferences = this.preferencesForm.value;
    preferences.banking = true;
    this.userService.saveUserPreferences(preferences).subscribe(() => { });
  }
  savePreferencesWithoutBanking() {
    let preferences = this.preferencesForm.value;
    preferences.banking = false;
    this.userService.saveUserPreferences(preferences).subscribe(() => { });
  }
  goBack() {
    this.settingsReference.backToSettings();
  }

}
