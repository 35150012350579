import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  private navigateToLoginPage(): void {
    // TODO: Remember current URL
    // this.router.navigate(['/landing']);
  }

  constructor(private oauthService: OAuthService, private router: Router) {
    // Useful for debugging:
    this.oauthService.events.subscribe(
      (event) => {
        if (event instanceof OAuthErrorEvent) {
          // console.error('OAuthErrorEvent Error Object:', event);
          this.isAuthenticatedSubject$.next(false);
        }
      },
      (err) => {
        // console.error(err);
      }
    );
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe(
      (_) => {
        this.isAuthenticatedSubject$.next(
          this.oauthService.hasValidAccessToken()
        );
      },
      (err) => {
        console.error(err);
      }
    );

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe(
        (e) => this.oauthService.loadUserProfile(),
        (err) => {}
      );

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe(
        (e) => this.navigateToLoginPage(),
        (err) => {}
      );

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      // console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        .then(() => new Promise<void>((resolve) => setTimeout(() => resolve(), 1000)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())
        .catch((err) => {})
        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }else{
            return Promise.reject();
          }
        })
        .catch((err) => {})
        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(targetUrl?: string): void {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout(): void {
    this.oauthService.logOut();
  }
  public refresh(): void {
    this.oauthService.silentRefresh();
  }
  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken(): string {
    return this.oauthService.getAccessToken() ?? '';
  }
  public get refreshToken(): string {
    return this.oauthService.getRefreshToken() ?? '';
  }
  public get identityClaims(): any {
    return this.oauthService.getIdentityClaims() ?? {};
  }
  public get idToken(): string {
    return this.oauthService.getIdToken() ?? '';
  }
  public get logoutUrl(): string {
    return this.oauthService.logoutUrl ?? '';
  }
}
