<nav class="navbar header-bar bottom-rounded">
  <div class="columns is-fullwidth" [ngClass]="isMobile?'is-mobile is-multiline': 'main_container_padding'">
    <div class="column has-text-centered" [ngClass]="isMobile?'is-full':'is-one-third has-text-left'"
      style="align-self: center">
      <p class="has-text-weight-bold title has-text-white">
        {{ "NEW_TRANSFER.YOUR_CONTACTS" | translate }}
      </p>
    </div>
    <div class="column has-text-right" [ngClass]="isMobile?'is-full':'is-one-third is-offset-one-third'">
      <div class="main-services">
        <div class="columns button-columns" [ngClass]="isMobile?'mobile-main-frame is-multiline':'main-frame'">
          <div class="column " [ngClass]="isMobile?'py-1':'is-narrow'">
            <button class="main-headerbutton" style="float: right" routerLink="/transactions/add-beneficiary">
              <div class="main-content">
                <p class="is-size-6 is-block has-text-primary mb-0 has-text-right">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faUserPlus" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0">{{ 'NEW_TRANSFER.ADD_NEW' | translate }}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="transaction-page">
  <div class="main_container_padding">
    <div class="p-4" *ngFor="let beneficiary of beneficiaries; let i = index"
      [ngClass]="isMobile?'mb-6':'mb-4 division-line'">
      <div class="columns">
        <div class="column m-auto">
          <div class="frame1000001816-frame1000001816">
            <div class="frame1000001816-avatar has-background-primary">
              <span class="frame1000001816-text ">
                <span>{{ beneficiary.initials }}</span>
              </span>
            </div>
            <div class="frame1000001816-frame22591">
              <span class="frame1000001816-text2 ">
                <span>{{ beneficiary.firstName }} {{ beneficiary.lastName }}</span>
                <img *ngIf="beneficiary.status === 'Trusted'" matTooltip="Trusted beneficiary"
                  src="assets/img/trust.png" style="
                  height: 1.5rem;
                  vertical-align: middle;
                  margin-left: 1%;
                " />
              </span>
              <span class="frame1000001816-text4">
                <span>{{ beneficiary.iban | maskedIban }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-3" [ngClass]="isMobile?'has-text-centered':'has-text-right'"
          style="flex: auto!important;">
          <button class="main-headerbutton bordered-headerbutton" style="float: right"
            routerLink="/transactions/new-transfer/{{ beneficiary.id }}">
            <div class="main-content">
              <p class="is-size-6 is-block has-text-primary mb-0 has-text-right">
                <fa-stack>
                  <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                  <fa-icon [icon]="faMoneyBillTransfer" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                </fa-stack>
              </p>
              <p class="m-0">{{ 'NEW_TRANSFER.SEND_MONEY_LABEL' | translate }}</p>
            </div>
          </button>
          <button class="main-headerbutton bordered-headerbutton" style="float: right"
            (click)="showDeleteBeneficiaryModal(beneficiary)">
            <div class="main-content">
              <p class="is-size-6 is-block has-text-primary mb-0 has-text-right">
                <fa-stack>
                  <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                  <fa-icon [icon]="faTrash" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                </fa-stack>
              </p>
              <p class="m-0">{{ 'BENEFICIARY.DELETE_CONTACT' | translate }}</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="totalBeneficiaries==0" style="position: relative;margin-top: 2rem;">
      <div class="p-4 mb-4 disabledDiv">
        <div class="columns">
          <div class="column">
            <div class="frame1000001816-frame1000001816">
              <div class="frame1000001816-avatar {{colorsClassesForBeneficiaries[1 % 4]}}">
                <span class="frame1000001816-text ">
                  <span>AP</span>
                </span>
              </div>
              <div class="frame1000001816-frame22591">
                <span class="frame1000001816-text2 ">
                  <span>Alexander Phillips</span>
                  <img matTooltip="Trusted beneficiary" src="assets/img/trust.png" style="
                  height: 1.5rem;
                  vertical-align: middle;
                  margin-left: 1%;
                " />
                </span>
                <span class="frame1000001816-text4">
                  <span>{{ 'GB33BUKB20201555555555' | maskedIban }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="column is-3" [ngClass]="isMobile?'has-text-centered':'has-text-right'"
            style="flex: auto!important;">
            <a class="main-icon-button mx-3" matTooltip="Send money">
              <fa-stack>
                <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                <fa-icon [icon]="faMoneyBillTransfer" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
              </fa-stack>
            </a>
            <a class="red-icon-button mx-3" matTooltip="Remove contact">
              <fa-stack>
                <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                <fa-icon [icon]="faTrash" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
              </fa-stack>
            </a>
          </div>
        </div>

      </div>
      <div class="centeredMessage">
        <div class="card dark-blue-background">
          <div class="card-content">
            <div class="content is-size-5">
              {{ 'NEW_TRANSFER.NO_CONTACTS'|translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-paginator *ngIf="totalBeneficiaries>0" [totalItems]="totalBeneficiaries" [reference]="this"></app-paginator>
  </div>
</div>
<div class="modal" [class.is-active]="showDeleteModal">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-card has-text-centered">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ 'BENEFICIARY.CONFIRMDELETION' | translate }}</p>
      <button class="delete" aria-label="close" (click)="showDeleteModal = false"></button>
    </header>
    <section class="modal-card-body">
      <p>{{ 'BENEFICIARY.CONFIRMDELETIONPROMPT' | translate }}</p>
    </section>
    <footer class="modal-card-foot " style="justify-content: center">
      <button class="button has-text-centered m-auto" style="width: 10rem;justify-content: center"
        (click)="showDeleteModal = false; beneficiaryToDelete = undefined">{{ 'SEND_MONEY.CANCEL_BUTTON' | translate }}
      </button>
      <button class="button has-text-centered is-danger m-auto" style="width: 10rem;justify-content: center"
        (click)="deleteBeneficiary()">{{ 'DELETE' | translate }}
      </button>
    </footer>
  </div>
</div>