<div style="padding: 1%;margin-top: 1%;" *ngIf="step !== 'completed'">
  <p *ngIf="step !== 'completed'" class="title is-size-4 mb-1">{{'VERIFYEMAIL' | translate}}</p>
  <p *ngIf="step !== 'completed'" class="subtitle is-size-5">{{'VERIFYEMAIL_DESCRIPTION' | translate}}</p>
  <form [formGroup]="emailFormValidationForm" *ngIf="step === 'send-code'" (submit)="submitCode()">
    <div class="columns">
      <div class="column">
        <div class="message is-danger" *ngIf="invalidCode">
          <!-- <div class="message-header">
            {{'FAILED' | translate}}
          </div> -->
          <div class="message-body content">
            <p class="title is-size-6 pb-2">{{'INVALIDCODE' | translate}}</p>
            <p class="subtitle is-size-6">{{'REQUESTNEWEMAILCODE' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main_container_padding py-4">
      <code-input class="code-input" (codeCompleted)="onOTPCodeCompleted($event)" [codeLength]="6"></code-input>
    </div>
    <div class="mb-3 mt-3">
      <button type="submit" [disabled]="!emailFormValidationForm.valid" class="button is-primary">{{'SENDCODE' |
        translate}}</button>
    </div>
    <div class="columns">
      <div class="column">
        <!-- <p>{{'MAYBEWRONGEMAILADDRESS' | translate}}</p> -->
        <button class="button mb-2" style="white-space: normal;" (click)="resendCode()">{{'CHECKEMAILADDRESS' |
          translate}}</button>
        <p>
          <mat-error *ngIf="successfullyResendCode" style="color: green;"
            [innerHtml]="'VALIDATIONMESSAGES.SUCCESSFULLYRESENDCODE' | translate">
          </mat-error>
        </p>
      </div>
    </div>
  </form>
  <div class="notification is-danger mt-5" *ngIf="showTooManyAttempts">
    <button class="delete" (click)="showTooManyAttempts = false"></button>
    {{'TOMANYATTEMPTS' | translate}}
  </div>
</div>
<div class="message is-success" style="padding: 1%;" *ngIf="step === 'completed'">
  <div class="message-header">
    <p>{{'EMAILVERIFIED' | translate}}</p>
    <button class="delete" aria-label="delete" (click)="finalise()"></button>

  </div>
  <div class="message-body has-text-black">
    <p class="title is-size-5 mb-4">
      {{'EMAILVERIFIEDEXP' | translate}}
    </p>
    <button class="button" (click)="finalise()">{{'OK' | translate}}</button>

  </div>
</div>