import {FormGroup} from '@angular/forms';
import {postcodeValidator, postcodeValidatorExistsForCountry} from 'postcode-validator';

// custom validator to check that two fields match
export function PostalCode(postalCode: string, countryCode: string): any {
  return (formGroup: FormGroup) => {
    const postalCodeControl = formGroup.controls[postalCode];
    const countryCodeControl = formGroup.controls[countryCode];
    const postalCodeValue = postalCodeControl.value;
    const countryValue = countryCode == 'ES' ? 'ES' : countryCodeControl.value;
    if (postcodeValidatorExistsForCountry(countryValue)) {
      if (postcodeValidator(postalCodeValue, countryValue)) {
        postalCodeControl.setErrors(null);
      } else {
        postalCodeControl.setErrors({invalidPostalCode: true});
      }
    }
  };
}
