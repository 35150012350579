import { Country } from '@angular-material-extensions/select-country';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FinancialDetails } from 'src/app/models/financial-details';
import { UserInfo } from 'src/app/models/user-info';
import { LanguageService } from 'src/app/core/services/language.service';
import { allowedCountries } from 'src/app/shared/allowedCountries';
import { UserService } from 'src/app/core/services/user.service';
import shortEnIAECodes from './profession_lists/en-IAECodes-short.json';
import shortEsIAECodes from './profession_lists/es-IAECodes-short.json';
import shortNoCnoCodes from './profession_lists/no-CnoCodes-short.json';
import shortEsCnoCodes from './profession_lists/es-CnoCodes-short.json';
import shortEnCnoCodes from './profession_lists/en-CnoCodes-short.json';
import shortFrCnoCodes from './profession_lists/fr-CnoCodes-short.json';
import shortDeCnoCodes from './profession_lists/de-CnoCodes-short.json';
import shortDaCnoCodes from './profession_lists/da-CnoCodes-short.json';
import shortSeCnoCodes from './profession_lists/se-CnoCodes-short.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})

export class UserInformationComponent implements OnInit {
  creatingAccount = false;
  @Input() userInfo!: UserInfo;
  filteredOptions!: any[];
  activitiesArray = shortEnCnoCodes as any[];
  removeHeader = false;
  allowedCountries = allowedCountries;
  residenceCountry!: Country;
  currentLanguage!: string;
  editMode = false;
  activityControl = new FormControl('', {
    validators: [Validators.required]
  });
  activity = "";
  IncomeLevelOptions!: { text: any; value: string; }[];
  CreditPerYearOptions!: { text: any; value: string; }[];
  IsPEPOptions!: { text: any; value: string; }[];

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private langService: LanguageService,
    private translateService: TranslateService
  ) {
  }

  userDetailsForm = this.formBuilder.group({
    profession: ['', { validators: [Validators.required], updateOn: 'change' }],
    activity: ['', { validators: [Validators.required], updateOn: 'change' }],
    professionalStatus: ['', { validators: [Validators.required], updateOn: 'change' }],
    fundMethod: ['', { validators: [Validators.required], updateOn: 'change' }],
    isPEP: ['', { validators: [Validators.required], updateOn: 'change' }],
    income_level: ['', { validators: [Validators.required], updateOn: 'change' }],
    creditPerYear: ['', { validators: [Validators.required], updateOn: 'change' }],
    pepCountry: ['', { updateOn: 'change' }],
    pepPosition: ['', { updateOn: 'change' }]
  });

  ngOnInit(): void {
    this.creatingAccount = false;
    this.activityControl.setValidators([Validators.required]);
    this.createOptions();
    this.activityControl.valueChanges.subscribe(value => {
      this.filteredOptions = this.filterActivity(value);
    });
    this.langService.getLanguage().subscribe((l) => {
      this.currentLanguage = l;
      //get value from professional status
      if (this.userDetailsForm.controls['professionalStatus'].value) {
        this.activitiesArray = this.getActivitiesArray(this.currentLanguage, this.userDetailsForm.controls['professionalStatus'].value);
      }
      this.activityControl.setValue(this.activity);
    });

    this.userDetailsForm.controls['professionalStatus'].valueChanges.subscribe(x => {
      if (x) {
        this.activitiesArray = this.getActivitiesArray(this.currentLanguage, x);
        this.activityControl.setValue(this.activity);
      }
    });
    this.userDetailsForm.controls['isPEP'].valueChanges.subscribe(x => {
      if (x === 'Yes') {
        this.userDetailsForm.controls['pepCountry'].setValidators([Validators.required]);
        this.userDetailsForm.controls['pepPosition'].setValidators([Validators.required]);
      } else {
        this.userDetailsForm.controls['pepCountry'].clearValidators();
        this.userDetailsForm.controls['pepCountry'].setValue(null);
        this.userDetailsForm.controls['pepCountry'].setErrors(null);
        this.userDetailsForm.controls['pepPosition'].clearValidators();
        this.userDetailsForm.controls['pepPosition'].setValue(null);
        this.userDetailsForm.controls['pepPosition'].setErrors(null);
      }
    });
    if (this.userInfo.financialDetails !== undefined && this.userInfo.financialDetails !== null) {
      // that allow edit
      this.editMode = true;
      this.activitiesArray = this.getActivitiesArray(this.currentLanguage, this.userInfo.financialDetails.professionalStatus);
      this.activity = this.activitiesArray.find(x => x.value === this.userInfo.financialDetails.activity)?.code ?? '';
      this.userDetailsForm.patchValue({
        profession: this.userInfo?.financialDetails?.profession,
        activity: this.userInfo.financialDetails.activity,
        professionalStatus: this.userInfo.financialDetails.professionalStatus,
        fundMethod: this.userInfo.financialDetails.fundMethod,
        isPEP: this.userInfo.financialDetails.isPEP ? 'Yes' : 'No',
        income_level: this.userInfo.financialDetails.incomeLevel,
        creditPerYear: this.userInfo.financialDetails.anticipatedCreditedAmountPerYear,
        pepCountry: this.userInfo.financialDetails.pepCountry ?? null,
        pepPosition: this.userInfo.financialDetails.pepPosition ?? null
      });

    }
  }

  createOptions(): void {
    this.IncomeLevelOptions = [
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.LESS_100000'),
        value: 'A'
      },
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.UP_TO_600000'),
        value: 'B'
      },
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.UP_TO_1200000'),
        value: 'C'
      },
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.MORE_THAN_1200000'),
        value: 'D'
      }
    ];
    this.CreditPerYearOptions = [
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.LESS_10000'),
        value: 'BELOW_10000'
      },
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.UP_TO_20000'),
        value: '10001_TO_20000'
      },
      {
        text: this.translateService.instant('USER_FINANCIAL_INFO.MORE_THAN_20000'),
        value: 'ABOVE_20000'
      }
    ];
    this.IsPEPOptions = [
      {
        text: this.translateService.instant('YES'),
        value: 'Yes'
      },
      {
        text: this.translateService.instant('NO'),
        value: 'No'
      }
    ];


  }
  handleCreditPerYearChange(event: any): void {
    this.userDetailsForm.get('creditPerYear')?.setValue(event);
  }

  handleIncomeLevelChange(event: any): void {
    this.userDetailsForm.get('income_level')?.setValue(event);
  }
  handleIsPEPChange(event: any): void {
    this.userDetailsForm.get('isPEP')?.setValue(event);
  }


  clearAutocomplete(): void {
    this.userDetailsForm.controls['activity'].setValue('');
    this.userDetailsForm.controls['profession'].setValue('');
    this.activityControl.setValue('');
    this.activityControl.setErrors(null);
    this.activity = '';
  }

  getActivitiesArray(currentLanguage: string, professionalStatus: string): any[] {
    let returnArray: any[];
    if (professionalStatus === 'Self-employed') {
      switch (currentLanguage) {
        case 'en':
          returnArray = shortEnIAECodes;
          break;
        case 'es':
          returnArray = shortEsIAECodes;
          break;
        default:
          returnArray = shortEnIAECodes;
          break;
      }
    } else {
      switch (currentLanguage) {
        case 'en':
          returnArray = shortEnCnoCodes;
          break;
        case 'es':
          returnArray = shortEsCnoCodes;
          break;
        case 'no':
          returnArray = shortNoCnoCodes;
          break;
        case 'fr':
          returnArray = shortFrCnoCodes;
          break;
        case 'de':
          returnArray = shortDeCnoCodes;
          break;
        case 'da':
          returnArray = shortDaCnoCodes;
          break;
        case 'se':
          returnArray = shortSeCnoCodes;
          break;
        default:
          returnArray = shortEnCnoCodes;
          break;
      }
    }
    return returnArray;
  }

  filterActivity(value: any): any {
    const filterValue = value.toLowerCase();
    const resultContains = this.activitiesArray.filter(option => option.code.toLowerCase().includes(filterValue));
    const resultEquals = this.activitiesArray.filter(option => option.code.toLowerCase() === filterValue);
    if (resultEquals.length === 1) {
      this.userDetailsForm.controls['activity'].setValue(resultEquals[0].value);
      this.userDetailsForm.controls['profession'].setValue(resultEquals[0].code);
      this.activityControl.setErrors(null);
    }
    if (this.userDetailsForm.controls['activity'].value == '' || this.userDetailsForm.controls['profession'].value == '') {
      this.activityControl.setErrors({ required: true });
    }
    return resultContains;
  }

  onResidenceCountryChange(country: Country): void {
    this.userDetailsForm.controls['pepCountry'].patchValue(country.alpha2Code);
  }

  registerBankingProvider(): void {
    this.userService.registerBankingProvider().subscribe(
      () => {
        this.router.navigate(['']);
      },
      (error) => {
        this.removeHeader = false;
        this.creatingAccount = false;
      }
    );
  }

  submitForm(): void {
    const financialDetails = new FinancialDetails();
    financialDetails.incomeLevel = this.userDetailsForm.controls['income_level'].value?.toString() ?? '';
    financialDetails.anticipatedCreditedAmountPerYear = this.userDetailsForm.controls['creditPerYear'].value?.toString() ?? '';
    financialDetails.profession = this.userDetailsForm.controls['profession'].value!;
    financialDetails.activity = this.userDetailsForm.controls['activity'].value?.toString() ?? '';
    financialDetails.pepCountry = this.userDetailsForm.controls['pepCountry'].value?.toString() ?? '';
    financialDetails.pepPosition = this.userDetailsForm.controls['pepPosition'].value?.toString() ?? '';
    financialDetails.professionalStatus = this.userDetailsForm.controls['professionalStatus'].value!;
    financialDetails.isPEP = this.userDetailsForm.controls['isPEP'].value === 'Yes';
    financialDetails.fundMethod = this.userDetailsForm.controls['fundMethod'].value?.toString() ?? '';
    this.creatingAccount = true;
    this.userService
      .updateUserFinancialDetails(financialDetails)
      .subscribe((x: any) => {
        if (this.editMode) {
          localStorage.setItem('lastEditedStep', 'Financial');
        }
        this.removeHeader = true;
        this.registerBankingProvider();
      },
        (error) => {
          this.creatingAccount = false;
          this.removeHeader = false;
        });
  }
}
