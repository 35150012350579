<div style="padding: 1%;margin-top: 1%;">
  <div class="alert alert-info">
    <p *ngIf="!user.kycInitialized" class="kyctext"  [innerHtml]="'ONBOARDING.KYC_INFO'|translate"></p>
    <p *ngIf="user.kycInitialized">{{'ONBOARDING.KYC_INITIALIZED_RETRY'|translate}}</p>
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <button class="button is-block is-primary is-fullwidth is-medium mt-5" (click)="startKyc()">{{'ONBOARDING.KYC_WEB_BUTTON'|translate}}</button>
      </div>
      <!-- <div class="column is-4">
        <button class="button is-block is-primary is-fullwidth is-medium mt-5" (click)="startKycSms()">{{'ONBOARDING.KYC_SMS_BUTTON'|translate}}</button>
      </div> -->
    </div>
    <!-- <div class="columns" *ngIf="!environment.production">
      <div class="column is-4 is-offset-4">
        <button class="button is-block is-primary is-fullwidth is-medium mt-5"
          (click)="simulateKycVerification()">Simulate KYC verification</button>
      </div>
    </div> -->
  </div>
</div>