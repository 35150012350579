import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppError, AppErrorDetails, InternalError } from 'src/app/shared/models/app-errors';
import { BuenoWebApiResult, BuenoWebApiResultGeneric } from 'src/app/shared/models/bueno-api-response';
import { OAuthService } from 'angular-oauth2-oidc';
import { BaseApiService } from './base-api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class KycService {
  constructor(private httpClient: HttpClient,
              private oAuth: OAuthService,
              @Inject('BASE_API_URL') private baseApiUrl: string,
              private baseApiService: BaseApiService,
              private userService: UserService) {
  }

  initializeKyc(kycModel: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<BuenoWebApiResult>(`${this.baseApiUrl}/kyc/RequestUrlKyc`,
      kycModel, { headers })
      .pipe(
        map((response: BuenoWebApiResult) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  initializeKycSms(kycModel: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<BuenoWebApiResult>(`${this.baseApiUrl}/kyc/RequestUrlKyc`,
      kycModel, { headers })
      .pipe(
        map((response: BuenoWebApiResult) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  setKycPending(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<BuenoWebApiResult>(`${this.baseApiUrl}/kyc/MarkAsPending`, { headers })
      .pipe(
        map((response: BuenoWebApiResult) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }


  simulateKycVerification(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<BuenoWebApiResult>(`${this.baseApiUrl}/kyc/SimulateKycVerification`,
      { headers })
      .pipe(
        map((response: BuenoWebApiResult) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }
}
