export class BuenoWebApiResult {
    code!: number;
    DetailedErrors: any[] = [];
    isSuccess = false;
    value: any;
    detailedErrors: any;
}

export class BuenoWebApiResultGeneric<TValue> extends BuenoWebApiResult {
    override value!: TValue;
}

export interface WebApiError {
    errorCode: string;
    errorDetails: string;
}

export enum BuenoWebApiResultStatus {
    Success,
    Fail
}
