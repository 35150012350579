<div class="cont-checkbox" (click)="toggleCheckbox()">
  <input type="checkbox" [id]="'myCheckbox-' + id" [checked]="checkedValue"/>
  <label>
    <img [src]="src"/>
    <span class="cover-checkbox is-block">
      <svg viewBox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </svg>
    </span>
    <div class="info">{{ text }}</div>
  </label>
</div>