import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BuenoWebApiResult, BuenoWebApiResultGeneric, } from 'src/app/shared/models/bueno-api-response';
import { RegisterUser } from '../../user/models/register-user';
import { OAuthService } from 'angular-oauth2-oidc';
import { IdentificationInfo, UserInfo } from '../../models/user-info';
import { UserLedger } from 'src/app/shared/models/user-ledger';
import { CardDeliveryAddress } from 'src/app/models/card-delivery.address';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { FinancialDetails } from 'src/app/models/financial-details';
import { CookieService } from 'ngx-cookie-service';

declare let seon: any;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url: string;
  private userInfoSubject = new BehaviorSubject<UserInfo>(new UserInfo());
  private isInitialized = false;

  constructor(
    private httpClient: HttpClient,
    private oAuth: OAuthService,
    @Inject('BASE_API_URL') private baseApiUrl: string,
    private baseApiService: BaseApiService,
    private cookieService: CookieService
  ) {
    this.url = `${this.baseApiUrl}`;
  }

  configureSeon(): void {
    if (this.cookieService.check('device_consent_id')) {
      this.initSeon();
    } else {
      this.consentDevice().subscribe((res) => {
        this.cookieService.set('device_consent_id', res.id, 365);
        this.initSeon();
      });
    }
  }

  initSeon(): void {
    seon.config({
      host: 'seondf.com',
      session_id: this.cookieService.get('device_consent_id'),
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess(): void {
        seon.getBase64Session((data: any): void => {
          if (data) {
            localStorage.setItem('seon_session', data);
            this.step = 'verifyEmail';
          } else {
          }
        });
      },
    });
  }

  getUserInfo(): Observable<UserInfo> {
    if (!this.isInitialized) {
      this.isInitialized = true;
      const token = this.oAuth.getIdToken();
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      if (localStorage.getItem('seon_session')) {
        headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
      }
      return this.httpClient
        .get(`${this.url}/users/GetUserInfo`, { headers })
        .pipe(
          map((response: any) => {
            this.userInfoSubject.next(response);
            return this.userInfoSubject.getValue();
          }),
          catchError((error) => {
            return throwError(this.baseApiService.handleError(error));
          })
        );
    } else {
      return this.userInfoSubject.asObservable();
    }
  }

  getTempIdentificationInfo(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<any>(`${this.url}/UserProfile/identification`, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  updateUserInfo(): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    this.httpClient
      .get(`${this.url}/users/GetUserInfo`, { headers })
      .pipe(
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      )
      .subscribe((res: any) => {
        this.userInfoSubject.next(res);
      });
    return of();
  }

  getReferrals(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<BuenoWebApiResultGeneric<UserInfo>>(
        `${this.url}/userprofile/referrals`,
        { headers }
      )
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  DocumentUpload(file: File, documentType: string, issuance: Date): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('document_type', documentType);
    formData.append('issuance', issuance.toISOString());
    const apiUrl = `${this.url}/users/DocumentUpload`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResultGeneric<any>>(apiUrl, formData, { headers })
      .pipe(
        map((response) => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  freeFirstMonth(): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResultGeneric<string>>(
        `${this.url}/payments/startmonth`,
        { headers }
      )
      .pipe(
        map((response: BuenoWebApiResultGeneric<string>) => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  stripeSubscription(priceId: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<BuenoWebApiResultGeneric<any>>(
        `${this.url}/payments/session?priceId=${priceId}`,
        { headers }
      )
      .pipe(
        map((response: BuenoWebApiResultGeneric<any>) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  stripeOnePayment(priceId: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<BuenoWebApiResultGeneric<any>>(`${this.url}/payments/product?priceId=${priceId}`, { headers })
      .pipe(
        map((response: BuenoWebApiResultGeneric<any>) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  stripeCustomerPortal(customerId: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<BuenoWebApiResultGeneric<any>>(`${this.url}/payments/customerportal?customerId=${customerId}`, { headers })
      .pipe(
        map((response: BuenoWebApiResultGeneric<any>) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  downloadStatement(from: string, to: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get(`${this.url}/pdf/MonthlyStatement?from=${from}&to=${to}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  downloadTransactionReceipt(movementId: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .get(`${this.url}/pdf/TransferConfirmation?movementId=${movementId}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );

  }

  register(
    registerUserModel: RegisterUser
  ): Observable<string> {
    return this.httpClient
      .post(`${this.url}/users/register`, registerUserModel)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error, true))
        )
      );
  }

  registerBankingProvider(): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResultGeneric<string>>(
        `${this.url}/users/CreateClient`,
        null,
        { headers }
      )
      .pipe(
        map((response: BuenoWebApiResultGeneric<string>) => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  resendConfirmationEmailLink(isPrimaryEmail: boolean): Observable<void> {
    const apiUrl = `${this.url}/account/requestcode`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResult>(
        apiUrl,
        { isPrimaryEmail, codeType: 0 },
        { headers }
      )
      .pipe(
        map((response) => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  confirmEmail(code: string, isPrimaryEmail: boolean): Observable<void> {
    const apiUrl = `${this.url}/account/confirmemail`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    code = code.trim();
    return this.httpClient
      .post<BuenoWebApiResult>(
        apiUrl,
        { isPrimaryEmail, codeType: 0, code },
        { headers }
      )
      .pipe(
        map(() => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error, true))
        )
      );
  }

  changeEmail(code: string, email: string): Observable<void> {
    const apiUrl = `${this.url}/account/changeEmail`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResult>(apiUrl, { code, email }, { headers })
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  changePhone(phoneCountryCode: string, phoneNumber: string, code: string): Observable<any> {
    const apiUrl = `${this.url}/Account/ChangePhone`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post(apiUrl, { phoneCountryCode, phoneNumber, code }, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  confirmPhoneChange(
    code: string,
    secondStepUpdate: boolean,
    newCountryCode: string,
    newPhone: string
  ): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    code = code.trim();
    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/confirmphone`,
        { code, secondStepUpdate, newCountryCode, newPhone },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  updateDeliveryAddress(deliveryAddress: CardDeliveryAddress): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<BuenoWebApiResult>(
        `${this.url}/userprofile/deliveryaddress`,
        deliveryAddress,
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  updateUserFinancialDetails(
    financialDetails: FinancialDetails
  ): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<BuenoWebApiResult>(
        `${this.url}/userprofile/financial`,
        financialDetails,
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  sendPhoneConfirmationCode(
    countryCode: number,
    phoneNumber: string
  ): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/sendPhoneConfirmationCode`,
        { phoneNumber: `${phoneNumber}` },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  setAddress(address: any, refresh: boolean): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<any>(`${this.url}/UserProfile/address`, address, { headers })
      .pipe(
        map(() => {
          if (refresh) {
            this.updateUserInfo();
          }
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  setLegalAddress(address: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .put<any>(`${this.url}/UserProfile/legaladdress`, address, { headers })
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  setPropertyAddress(index: number, isPrimary: boolean, address1: string, address2: string,
    postalCode: string, city: string, state: string, countryCode: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<any>(
        `${this.url}/UserProfile/additionalAddress`,
        {
          index,
          isPrimary,
          address1,
          address2,
          postalCode,
          city,
          state,
          countryCode,
        },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  setPersonalDetails(nationality: any, birthdate: any, gender: any, birthCountry: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .put(`${this.url}/UserProfile/personal`, {
        Nationality: nationality,
        Birthday: birthdate,
        Gender: gender,
        BirthCountry: birthCountry,
      }, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  setIdentificationDetails(
    identification: IdentificationInfo,
  ): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .put(
        `${this.url}/UserProfile/identification`,
        {
          identificationInfo: identification
        },
        { headers }
      )
      .pipe(
        map((response: any) => {
          this.updateUserInfo();
          return response;
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  // TODO: Check in the API if this endpoint will be even needed and remove it if won't.
  // updateAddress(updateAddress: UpdateAddress): Observable<any> {
  //   const token = this.oAuth.getIdToken();
  //   const headers = new HttpHeaders()
  //     .set('Authorization', 'Bearer ' + token);
  //   return this.httpClient.put<BuenoWebApiResult>(`${this.url}/userprofile/address`,
  //     updateAddress, {headers} )
  //     .pipe(
  //       map((response: any) => response.value),
  //       catchError(error => throwError(this.baseApiService.handleError(error))));
  // }

  getAddress(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<any>(`${this.url}/UserProfile/address`, { headers })
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getLedgers(): Observable<UserLedger[]> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient.get<any>(`${this.url}/Ledgers`, { headers }).pipe(
      map((response: any) => response.value),
      catchError((error) => throwError(this.baseApiService.handleError(error)))
    );
  }

  getBalance(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .get<any>(`${this.url}/account/Balance`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getAdditionalAddresses(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<any>(`${this.url}/UserProfile/additionaladdress`, { headers })
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getProperties(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<any>(`${this.url}/property`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  deleteProperty(propertyIndex: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .delete<any>(`${this.url}/property/${propertyIndex}`, {
        headers,
      })
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  updateProperty(propertyUpdated: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<any>(`${this.url}/property`, propertyUpdated, {
        headers,
      })
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  addProperty(property: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<any>(`${this.url}/property`, property, { headers })
      .pipe(
        map((response: any) => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  confirmPhone(code: string): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    code = code.trim();
    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/confirmPhone`,
        { code },
        { headers }
      )
      .pipe(
        map(() => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  forgotPassword(email: string): Observable<void> {
    return this.httpClient
      .post<BuenoWebApiResult>(`${this.url}/account/forgotPassword`, { email })
      .pipe(
        map((result: BuenoWebApiResult) => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  forgotPasswordChange(code: string, email: string, newPassword: string): Observable<void> {
    return this.httpClient
      .post<BuenoWebApiResult>(`${this.url}/account/forgotPasswordChange`, {
        code,
        email,
        newPassword,
      })
      .pipe(
        map((result: BuenoWebApiResult) => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  requestCode(codeType: number): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/requestCode`,
        { codeType },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  changePassword(
    code: string,
    password: string,
    confirmPassword: string
  ): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    code = code.trim();
    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/changePassword`,
        { code, password, confirmPassword },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  validatePassword(password: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<BuenoWebApiResult>(
        `${this.url}/account/ValidatePassword`,
        { password },
        { headers }
      )
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getSecurityQuestions(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient.get(`${this.url}/users/Questions`, { headers }).pipe(
      map((response: any) => response.value),
      catchError((error) => throwError(this.baseApiService.handleError(error)))
    );
  }

  updateSecurityQuestions(questions: any[]): Observable<void> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<BuenoWebApiResult>(
        `${this.url}/users/Questions`,
        { questions },
        { headers }
      )
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getOneTimeToken(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .get<BuenoWebApiResult>(`${this.url}/users/OneTimeAuthToken`, { headers })
      .pipe(
        map((response: any) => response.value),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  changePreferredLanguage(language: string): Observable<void> {
    const model = { PreferredLanguage: language };
    const token = this.oAuth.getIdToken();
    const apiUrl = `${this.url}/account/changepreferredlanguage`;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .put<BuenoWebApiResult>(apiUrl, model, { headers })
      .pipe(
        map(() => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  singDocument(documentType: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<any>(
        `${this.url}/users/DocumentSigned`,
        { documentType },
        { headers }
      )
      .pipe(
        map(() => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  createAccount(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<any>(`${this.url}/account/OpenProviderAccount`, {}, { headers })
      .pipe(
        map(() => {
          this.updateUserInfo();
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  consentDevice(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }

    return this.httpClient
      .post<any>(`${this.url}/device/consent`, {}, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  testErrorCode(errorCode: string): Observable<void> {
    return this.httpClient
      .get<BuenoWebApiResult>(`${this.url}/test/error?errorCode=${errorCode}`)
      .pipe(
        map(() => {
        }),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getPostBoxItems(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .get<BuenoWebApiResult>(`${this.url}/Postbox`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  confirmPostBoxItem(file: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .post<BuenoWebApiResult>(`${this.url}/Postbox/confirmation`, { ItemId: file.id }, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  downloadPostBoxItem(file: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .get(`${this.url}/Postbox/download/${file.postboxItemId}/${file.name}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }


  getNotificationsSettings(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .get(`${this.url}/users/notificationFlags`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }


  updateNotificationsSettings(value: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    return this.httpClient
      .patch(`${this.url}/users/notificationFlags`, value, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  removeAllFiles(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .delete(`${this.url}/Users/DeleteNieDocuments`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  checkCardsRequeriments(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .get(`${this.url}/cards/check`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error, true))
        )
      );
  }

  getCards(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .get(`${this.url}/cards`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  createCard(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .post(`${this.url}/cards/create`, {}, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  fundCard(amount: any) {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .post(`${this.url}/cards/fund`, { amount }, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  saveUserPreferences(value: Partial<{ insurance: boolean | null; homeAlarm: boolean | null; nonResidentTax: boolean | null; electricity: boolean | null; }>) {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .post(`${this.url}/users/interest`, value, { headers })
      .pipe(
        map((response: any) => this.updateUserInfo()),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  downloadAccountCertificate(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient
      .get(`${this.url}/pdf/AccountCertificate`, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );

  }

  getDirectDebitsProviders(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .get(`${this.url}/directDebit/providers`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  submitDirectDebitApplication(application: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .post(`${this.url}/directDebit/application`, application, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  getDirectDebitApplications(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .get(`${this.url}/directDebit/applications`, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  editDirectDebitApplication(application: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .put(`${this.url}/directDebit/application`, application, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }

  cancelDirectDebitApplication(application: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient
      .put(`${this.url}/directDebit/application/cancel`, application, { headers })
      .pipe(
        map((response: any) => response),
        catchError((error) =>
          throwError(this.baseApiService.handleError(error))
        )
      );
  }
}

