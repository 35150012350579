import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TransfersBeneficiary } from 'src/app/user/models/transfers-beneficiary';
import { BeneficiaryService } from 'src/app/core/services/beneficiary.service';
import { faCircle, faMoneyBillTransfer, faTrash, faUserPlus } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  beneficiaries!: any[];
  pageSize = 10;
  page = 0;
  totalBeneficiaries: any;
  faCircle = faCircle;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faMoneyBillTransfer = faMoneyBillTransfer;
  isMobile = window.innerWidth <= 770;

  @ViewChild(MatPaginator) beneficiariesPaginator!: MatPaginator;
  colorsClassesForBeneficiaries = [
    'has-background-primary',
    'has-background-success',
    'has-background-warning',
    'has-background-danger'
  ];
  showDeleteModal: boolean = false;
  beneficiaryToDelete!: any;

  constructor(private beneficiaryService: BeneficiaryService) {
  }

  ngOnInit(): void {
    this.getBeneficiaries();
  }

  getBeneficiaries(): void {
    this.beneficiaryService.getBeneficiaries('',
      this.page * this.pageSize,
      this.pageSize).subscribe((beneficiaries) => {
        if (beneficiaries.beneficiaries instanceof Array) {
          this.beneficiaries = [];
          beneficiaries.beneficiaries.forEach((element: any) => {
            const beneficiary = element as TransfersBeneficiary;
            const splittedName = beneficiary.name.split(' ');
            beneficiary.firstName = splittedName[0];
            beneficiary.lastName = splittedName[1] || '';
            beneficiary.initials = `${beneficiary.firstName[0]} ${beneficiary.lastName != '' ? beneficiary.lastName[0] : ''}`;
            this.beneficiaries.push(beneficiary);
          });
        }
        this.totalBeneficiaries = this.beneficiaries.length;
      });
  }

  pageChanged(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.getBeneficiaries();
  }

  showDeleteBeneficiaryModal(beneficiary: any): void {
    if (beneficiary) {
      this.showDeleteModal = true;
      this.beneficiaryToDelete = beneficiary;
    }
  }

  deleteBeneficiary(): void {
    const beneficiary = this.beneficiaryToDelete;
    if (beneficiary) {
      const beneficiaryToken = beneficiary.id;
      this.beneficiaryService.deleteBeneficiary(beneficiaryToken).subscribe((res) => {
        this.beneficiaries = this.beneficiaries.filter((obj) => obj.id !== beneficiaryToken);
        this.showDeleteModal = false;
      });
    }
  }
}
