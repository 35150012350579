<div class="mt-1">
  <p *ngIf="!removeHeader" class="title is-size-4">{{ 'USER_FINANCIAL_INFO.TITLE'|translate }}</p>
  <p *ngIf="!removeHeader" class="subtitle is-size-6">{{ 'USER_FINANCIAL_INFO.SUBTITLE'|translate }}</p>

  <div>
    <form [formGroup]="userDetailsForm" id="userDetailsForm" *ngIf="!creatingAccount" (submit)="submitForm()">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'USER_FINANCIAL_INFO.PROFESSION_STATUS'|translate }}</mat-label>
        <mat-select formControlName="professionalStatus">
          <mat-option value="Full-time employee">{{ 'USER_FINANCIAL_INFO.FULL_TIME_EMPLOYEE'|translate }}</mat-option>
          <mat-option value="Part time employee">{{ 'USER_FINANCIAL_INFO.PART_TIME_EMPLOYEE'|translate }}</mat-option>
          <mat-option value="Self-employed">{{ 'USER_FINANCIAL_INFO.SELF_EMPLOYED'|translate }}</mat-option>
          <mat-option value="Unemployed">{{ 'USER_FINANCIAL_INFO.UNEMPLOYED'|translate }}</mat-option>
          <mat-option value="Student">{{ 'USER_FINANCIAL_INFO.STUDENT'|translate }}</mat-option>
          <mat-option value="Pensioner">{{ 'USER_FINANCIAL_INFO.PENSIONER'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'USER_FINANCIAL_INFO.PROFESSION_ACTIVITY'|translate }}</mat-label>
        <input type="text" [placeholder]="'PICK_ONE'|translate" aria-label="Currency" matInput
          [formControl]="activityControl" [matAutocomplete]="auto">
        <mat-error *ngIf="activityControl.hasError('required')"
          [innerHTML]="'ERROR_CODES.PROFESSION_ACTIVITY_REQUIRED'|translate">
        </mat-error>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions" [value]="option.code">
            {{ option.code }}
          </mat-option>
        </mat-autocomplete>
        <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearAutocomplete()">
          <mat-icon style="align-content: center;">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'USER_FINANCIAL_INFO.FUND_METHOD'|translate }}</mat-label>
        <mat-select formControlName="fundMethod">
          <mat-option value="Salary">{{ 'USER_FINANCIAL_INFO.SALARY'|translate }}</mat-option>
          <mat-option value="Savings">{{ 'USER_FINANCIAL_INFO.SAVINGS'|translate }}</mat-option>
          <mat-option value="Heritage">{{ 'USER_FINANCIAL_INFO.HERITAGE'|translate }}</mat-option>
          <mat-option value="Credit loan">{{ 'USER_FINANCIAL_INFO.CREDIT_LOAN'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="no_background">
        <p>{{ 'USER_FINANCIAL_INFO.ANNUAL_VOLUME'|translate }}</p>
        <image-radio-group [options]="CreditPerYearOptions" [identifier]="'creditPerYear'"
          (valueChange)="handleCreditPerYearChange($event)"></image-radio-group>

      </div>
      <div class="no_background">
        <p>{{ 'USER_FINANCIAL_INFO.ANNUAL_INCOME'|translate }}</p>
        <image-radio-group [options]="IncomeLevelOptions" [identifier]="'incomeLevel'"
          (valueChange)="handleIncomeLevelChange($event)"></image-radio-group>
      </div>
      <div class="no_background">
        <p>{{ 'USER_FINANCIAL_INFO.PEP'|translate }}</p>
        <image-radio-group [options]="IsPEPOptions" [identifier]="'isPEP'"
          (valueChange)="handleIsPEPChange($event)"></image-radio-group>

        <div *ngIf="userDetailsForm.controls.isPEP.value === 'Yes'">
          <div class="columns  is-vcentered">
            <div class="column">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'USER_FINANCIAL_INFO.PEP_POSITION'|translate }}</mat-label>
                <input matInput formControlName="pepPosition">
              </mat-form-field>
            </div>
            <div class="column">
              <mat-select-country appearance="outline" label="{{'USER_FINANCIAL_INFO.PEP_COUNTRY' | translate}}"
                [countries]="allowedCountries" [value]="residenceCountry"
                (onCountrySelected)="onResidenceCountryChange($event)">
              </mat-select-country>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button type="submit" [disabled]="!userDetailsForm.valid"
          class="button is-block is-primary is-fullwidth is-medium mt-5">{{ 'NEXT' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="creatingAccount" style="display: inline-block;">
    <mat-spinner></mat-spinner>
  </div>
</div>