<div style="padding: 1%;margin-top: 1%;">
  <p class="title is-size-4">{{ 'PASSPORT_DETAILS_TITLE' | translate }}</p>
  <div class="no_background">
    <form [formGroup]="nieForm" id="dniForm" (submit)="submitForm()">
      <div class="columns is-vcentered mx-4 mt-2" style="background-color: #f7fafe; border-radius: 1rem">
        <div class="column is-one-quarter">
          <img src="assets/img/security.png" style="max-height: 10rem" alt="security">
        </div>
        <div class="column is-three-quarter">
          <p class="subtitle">
            {{ 'ID_TRUST_HEADER' | translate }}
          </p>
          <p class="main_container_padding">
            {{ 'ID_TRUST_INFO' | translate }}
          </p>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'NIENUMBER' | translate }}</mat-label>
            <input type="text" appFormInput matInput mask="S-0000000-S" formControlName="taxIdNumber"/>
            <mat-error *ngIf="nieForm.get('taxIdNumber')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.NIENUMBERISREQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="nieForm.get('taxIdNumber')!.hasError('invalidFormat')"
                       [innerHtml]="'VALIDATIONMESSAGES.DNINIEFORMAT' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'PASSPORT_ISSUING_MONTH' | translate }}</mat-label>
            <mat-select formControlName="taxIdIssuingMonth">
              <mat-option value="1">Enero</mat-option>
              <mat-option value="2">Febrero</mat-option>
              <mat-option value="3">Marzo</mat-option>
              <mat-option value="4">Abril</mat-option>
              <mat-option value="5">Mayo</mat-option>
              <mat-option value="6">Junio</mat-option>
              <mat-option value="7">Julio</mat-option>
              <mat-option value="8">Agosto</mat-option>
              <mat-option value="9">Septiembre</mat-option>
              <mat-option value="10">Octubre</mat-option>
              <mat-option value="11">Noviembre</mat-option>
              <mat-option value="12">Diciembre</mat-option>
            </mat-select>
            <mat-error *ngIf="nieForm.get('taxIdIssuingMonth')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.IDEXPIRYDATEREQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="nieForm.get('taxIdIssuingMonth')!.hasError('invalidDate')"
                       [innerHtml]="'ERROR_CODES.NIE_DATE_INVALID' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'PASSPORT_ISSUING_YEAR' | translate }}</mat-label>
            <mat-select formControlName="taxIdIssuingYear">
              <!-- <mat-option value="2030">2030 - dos mil treinta</mat-option>
              <mat-option value="2029">2029 - dos mil veintinueve</mat-option>
              <mat-option value="2028">2028 - dos mil veintiocho</mat-option>
              <mat-option value="2027">2027 - dos mil veintisiete</mat-option>
              <mat-option value="2026">2026 - dos mil veintiséis</mat-option>
              <mat-option value="2025">2025 - dos mil veinticinco</mat-option>-->
              <mat-option value="2024">2024 - dos mil veinticuatro</mat-option>
              <mat-option value="2023">2023 - dos mil veintitrés</mat-option>
              <mat-option value="2022">2022 - dos mil veintidós</mat-option>
              <mat-option value="2021">2021 - dos mil veintiuno</mat-option>
              <mat-option value="2020">2020 - dos mil veinte</mat-option>
              <mat-option value="2019">2019 - dos mil diecinueve</mat-option>
              <mat-option value="2018">2018 - dos mil dieciocho</mat-option>
              <mat-option value="2017">2017 - dos mil diecisiete</mat-option>
              <mat-option value="2016">2016 - dos mil dieciséis</mat-option>
              <mat-option value="2015">2015 - dos mil quince</mat-option>
              <mat-option value="2014">2014 - dos mil catorce</mat-option>
              <mat-option value="2013">2013 - dos mil trece</mat-option>
              <mat-option value="2012">2012 - dos mil doce</mat-option>
              <mat-option value="2011">2011 - dos mil once</mat-option>
              <mat-option value="2010">2010 - dos mil diez</mat-option>
              <mat-option value="2009">2009 - dos mil nueve</mat-option>
              <mat-option value="2008">2008 - dos mil ocho</mat-option>
              <mat-option value="2007">2007 - dos mil siete</mat-option>
              <mat-option value="2006">2006 - dos mil seis</mat-option>
              <mat-option value="2005">2005 - dos mil cinco</mat-option>
              <mat-option value="2004">2004 - dos mil cuatro</mat-option>
              <mat-option value="2003">2003 - dos mil tres</mat-option>
              <mat-option value="2002">2002 - dos mil dos</mat-option>
              <mat-option value="2001">2001 - dos mil uno</mat-option>
              <mat-option value="2000">2000 - dos mil</mat-option>
              <mat-option value="1999">1999 - mil novecientos noventa y nueve</mat-option>
              <mat-option value="1998">1998 - mil novecientos noventa y ocho</mat-option>
              <mat-option value="1997">1997 - mil novecientos noventa y siete</mat-option>
              <mat-option value="1996">1996 - mil novecientos noventa y seis</mat-option>
              <mat-option value="1995">1995 - mil novecientos noventa y cinco</mat-option>
              <mat-option value="1994">1994 - mil novecientos noventa y cuatro</mat-option>
              <mat-option value="1993">1993 - mil novecientos noventa y tres</mat-option>
              <mat-option value="1992">1992 - mil novecientos noventa y dos</mat-option>
              <mat-option value="1991">1991 - mil novecientos noventa y uno</mat-option>
              <mat-option value="1990">1990 - mil novecientos noventa</mat-option>
              <mat-option value="1989">1989 - mil novecientos ochenta y nueve</mat-option>
              <mat-option value="1988">1988 - mil novecientos ochenta y ocho</mat-option>
              <mat-option value="1987">1987 - mil novecientos ochenta y siete</mat-option>
              <mat-option value="1986">1986 - mil novecientos ochenta y seis</mat-option>
              <mat-option value="1985">1985 - mil novecientos ochenta y cinco</mat-option>
              <mat-option value="1984">1984 - mil novecientos ochenta y cuatro</mat-option>
              <mat-option value="1983">1983 - mil novecientos ochenta y tres</mat-option>
              <mat-option value="1982">1982 - mil novecientos ochenta y dos</mat-option>
              <mat-option value="1981">1981 - mil novecientos ochenta y uno</mat-option>
              <mat-option value="1980">1980 - mil novecientos ochenta</mat-option>
            </mat-select>
            <mat-error *ngIf="nieForm.get('taxIdIssuingYear')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.IDEXPIRYDATEREQUIRED' | translate">
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="is-fullwidth">
        <mat-label style="display: block;">{{ 'NIEPHOTODESCRIPTION'|translate }}</mat-label>
        <mat-label class="is-italic is-size-7	" style="display: block;">
          {{ 'NIEPHOTODESCRIPTIONEXTRAS'|translate }}
        </mat-label>
        <img src="assets/img/NIE.png" alt="NIE" style="max-height: 20vh;">
      </div>

      <button type="button" class="button is-primary"
              [disabled]="!validDate || !nieForm.get('taxIdNumber')?.valid || successfullyUploaded"
              mat-raised-button
              (click)="showPreviewFunction()">{{ 'UPLOADPDF'|translate }}
      </button>
      <button type="button" class="button m-2 is-primary"
              [disabled]="!validDate || !nieForm.get('taxIdNumber')?.valid || successfullyUploaded"
              mat-raised-button
              (click)="imageInput.click()">{{ 'TAKEPICTURE'|translate }}
      </button>
      <button type="button" class="button m-2 is-primary" *ngIf="successfullyUploaded" mat-raised-button
              (click)="removeAllFiles()">{{ 'REMOVE_ALL_FILES'|translate }}
      </button>
      <input hidden (change)="onFileSelected($event, 'NIE')" #imageInput type="file" capture="camera"
             accept=".png, .jpg, .jpeg" id="image">
      <mat-error *ngIf="nieForm.get('taxIdImage')!.hasError('invalidSize')"
                 [innerHtml]="'VALIDATIONMESSAGES.INVALIDFILESIZE' | translate">
      </mat-error>
      <mat-error *ngIf="successfullyUploaded" style="color: green;"
                 [innerHtml]="'VALIDATIONMESSAGES.SUCCESSFULLYUPLOADED' | translate">
      </mat-error>
      <mat-error *ngIf="successfullyDeleted" style="color: green;"
                 [innerHtml]="'VALIDATIONMESSAGES.SUCCESSFULLYDELETED' | translate">
      </mat-error>
      <button type="submit" [disabled]="!nieForm.valid || !successfullyUploaded || !validDate || uploadingImage"
              class="button is-block is-primary is-fullwidth is-medium mt-5">{{ 'NEXT' | translate }}
      </button>
    </form>
  </div>
</div>

<div class="modal" [class.is-active]="uploadingImage">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content has-background-white">
    <div class="box shadow" style="text-align: center;">
      <p style="font-size: 18px;">{{ 'LOADINGMODAL' | translate }}</p>
      <br>
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'3x'"></fa-icon>
    </div>
  </div>
</div>
<div class="modal" id="previewPDF" [class.is-active]="showPreview">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content onboarding-modal" style="position: fixed;top:2vh">
    <mat-label class="mt-4" style="display: block;">{{ 'PDFUPLOADDESCRIPTION'|translate }}</mat-label>
    <div class="columns">
      <div class="column is-offset-2 is-3">
        <button type="button" class="button m-2 is-primary is-fullwidth" mat-raised-button *ngIf="!hasFileLoaded"
                (click)="showPreviewFunction()">{{ 'CHOOSEFILE'|translate }}
        </button>
        <button type="button" *ngIf="hasFileLoaded" class="button m-2 is-primary is-fullwidth" mat-raised-button
                [disabled]="!enableUpload" (click)="uploadPDFImage('NIE')">{{ 'UPLOADPDF'|translate }}
        </button>
      </div>
      <div class="column is-offset-2 is-3">
        <button type="button" class="button m-2 is-danger is-fullwidth" mat-raised-button
                (click)="closePreview()">{{ 'CLOSE'|translate }}
        </button>
      </div>
    </div>
    <input hidden (change)="onFileSelected($event,'NIE')" #fileInput type="file" accept=".pdf" id="file">
    <mat-error *ngIf="nieForm.get('taxIdImage')!.hasError('invalidSize')"
               [innerHtml]="'VALIDATIONMESSAGES.INVALIDFILESIZE' | translate">
    </mat-error>
    <div class="pdf-container" style="height: 85vh;" *ngIf="pdfSrc!=''">
      <ngx-extended-pdf-viewer [src]="pdfSrc" [(zoom)]="zoomSetting"
                               [customToolbar]="additionalButtons"></ngx-extended-pdf-viewer>
      <ng-template #additionalButtons>
        <div id="toolbarViewerLeft">
          <pdf-paging-area></pdf-paging-area>
        </div>
      </ng-template>
    </div>
  </div>
</div>
