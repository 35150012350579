import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LanguageService {
  LanguageChanged = new ReplaySubject<string>();
  constructor() {

  }
  getLanguage(): Observable<string> {
    return this.LanguageChanged.asObservable();
  }

  setLanguage(lang: string): void {
    this.LanguageChanged.next(lang);
  }

}
