<div class="mt-1">
    <div *ngIf="!settingsReference">
        <p class="title">{{ 'USER_PREFERENCES.TITLE'|translate }}</p>
        <p class="subtitle mt-2">{{ 'USER_PREFERENCES.DESCRIPTION'|translate }}</p>
    </div>
    <p class="is-size-6 has-text-weight-bold has-text-centered p-4" *ngIf="settingsReference">
        {{'USER_PREFERENCES.EDIT_DESCRIPTION' | translate}}</p>
    <div class="columns my-4" [ngClass]="settingsReference?'is-multiline':''">
        <div class="column m-auto">
            <image-checkbox [src]="'/assets/img/icons/accidental-risk.png'"
                [text]="'USER_PREFERENCES.INSURANCE_CHECK' | translate" [checkedValue]="preferencesForm.value.insurance"
                (checked)="insuranceCheckedChange($event)"></image-checkbox>
        </div>
        <div class="column m-auto">
            <image-checkbox [src]="'/assets/img/icons/alarm.png'"
                [text]="'USER_PREFERENCES.HOME_ALARM_CHECK' | translate"
                [checkedValue]="preferencesForm.value.homeAlarm"
                (checked)="homeAlarmCheckedChange($event)"></image-checkbox>
        </div>
        <div class="column m-auto">
            <image-checkbox [src]="'/assets/img/icons/civil-laibility.png'"
                [text]="'USER_PREFERENCES.NON_RESIDENT_TAX' | translate"
                [checkedValue]="preferencesForm.value.nonResidentTax"
                (checked)="nonResidentTaxCheckedChange($event)"></image-checkbox>
        </div>
        <div class="column m-auto">
            <image-checkbox [src]="'/assets/img/icons/Frame_electricity.png'"
                [text]="'USER_PREFERENCES.ELECTRICITY' | translate" [checkedValue]="preferencesForm.value.electricity"
                (checked)="electricityCheckedChange($event)"></image-checkbox>
        </div>
    </div>
    <div class=" is-flex has-text-centered mb-3">
        <button *ngIf="userSettings" type="button" (click)="savePreferences()"
            class="button is-block is-primary m-auto is-medium mt-5">
            {{ 'SAVECHANGES'| translate }}
        </button>
        <div class="columns" *ngIf="!userSettings " [ngClass]="isMobile?'mobile_buttons': 'desktop_buttons'">
            <div class="column">
                <button type="button" (click)="savePreferencesWithoutBanking()"
                    class="button is-block is-outlined m-auto is-medium mt-5 normal-whitespace"
                    [disabled]=" !preferencesForm.value.insurance && !preferencesForm.value.homeAlarm && !preferencesForm.value.nonResidentTax && !preferencesForm.value.electricity">
                    {{ 'USER_PREFERENCES.NON_BANKING_BUTTON'| translate }}
                </button>
            </div>
            <div class="column">
                <button type="button" (click)="savePreferencesWithBanking()"
                    class="button is-block is-primary m-auto is-medium mt-5 normal-whitespace">
                    {{ 'USER_PREFERENCES.BANKING_BUTTON'| translate }}
                </button>
            </div>
        </div>
    </div>
</div>