export class FinancialDetails {
    profession!: string;
    professionalStatus!: string;
    incomeLevel!: string;
    anticipatedCreditedAmountPerYear!: string;
    activity!: string;
    isPEP!: boolean;
    pepPosition!: string;
    pepCountry!: string;
    fundMethod!: string;
}
