import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent {
  @Input() resubscription: boolean = false
  @Input() reference: any

  constructor() {
  }

  subscribeYearly() {
    this.reference.yearlyPayment();
  }

  subscribeMonthly() {
    this.reference.monthlyPayment();
  }

}
