import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { map, catchError, throwError } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AyuwareService {
  apiKey = 'cad5f547d08715218434b76ef0d5e7c9';
  constructor(
    private httpClient: HttpClient,
    private baseApiService: BaseApiService,
    private oAuth: OAuthService,
    @Inject('BASE_API_URL') private baseApiUrl: string,
  ) { }

  autocompleteAddress(address: string) {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    let url = `${this.baseApiUrl}/proxy/ayuware/autocomplete?query=${address}`;
    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((response: any) => JSON.parse(response)),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  validateAddress(address: any) {
    let ineCode = address.cod_ine;
    let streetId = address.cod_via;
    let number = address.numero_via;
    let url = `${this.baseApiUrl}/proxy/ayuware/validation?ineCode=${ineCode}&streetId=${streetId}&number=${number}`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((response: any) => JSON.parse(response)),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  getPropertyData(address: any) {
    let catastralNumber = address.referencia_catastral;
    let streetId = address.cod_via;
    let number = address.numero_via;
    let url = `${this.baseApiUrl}/proxy/ayuware/property?catastralNumber=${catastralNumber}&streetId=${streetId}&number=${number}`;
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    if (localStorage.getItem('seon_session')) {
      headers.set('DeviceFingerprint', localStorage.getItem('seon_session')!);
    }
    return this.httpClient.get<any>(url, { headers })
      .pipe(
        map((response: any) => JSON.parse(response)),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }



  getProvinceFromCode(cod_prov: any): any {
    let provinceCode;
    switch (cod_prov) {
      case '01':
        provinceCode = 'ES-VI';
        break;
      case '02':
        provinceCode = 'ES-AB';
        break;
      case '03':
        provinceCode = 'ES-A';
        break;
      case '04':
        provinceCode = 'ES-AL';
        break;
      case '05':
        provinceCode = 'ES-AV';
        break;
      case '06':
        provinceCode = 'ES-BA';
        break;
      case '07':
        provinceCode = 'ES-PM';
        break;
      case '08':
        provinceCode = 'ES-B';
        break;
      case '09':
        provinceCode = 'ES-BU';
        break;
      case '10':
        provinceCode = 'ES-CC';
        break;
      case '11':
        provinceCode = 'ES-CA';
        break;
      case '12':
        provinceCode = 'ES-CS';
        break;
      case '13':
        provinceCode = 'ES-CR';
        break;
      case '14':
        provinceCode = 'ES-CO';
        break;
      case '15':
        provinceCode = 'ES-C';
        break;
      case '16':
        provinceCode = 'ES-CU';
        break;
      case '17':
        provinceCode = 'ES-GI';
        break;
      case '18':
        provinceCode = 'ES-GR';
        break;
      case '19':
        provinceCode = 'ES-GU';
        break;
      case '20':
        provinceCode = 'ES-SS';
        break;
      case '21':
        provinceCode = 'ES-H';
        break;
      case '22':
        provinceCode = 'ES-HU';
        break;
      case '23':
        provinceCode = 'ES-J';
        break;
      case '24':
        provinceCode = 'ES-LE';
        break;
      case '25':
        provinceCode = 'ES-L';
        break;
      case '26':
        provinceCode = 'ES-LO';
        break;
      case '27':
        provinceCode = 'ES-LU';
        break;
      case '28':
        provinceCode = 'ES-M';
        break;
      case '29':
        provinceCode = 'ES-MA';
        break;
      case '30':
        provinceCode = 'ES-MU';
        break;
      case '31':
        provinceCode = 'ES-NA';
        break;
      case '32':
        provinceCode = 'ES-OR';
        break;
      case '33':
        provinceCode = 'ES-O';
        break;
      case '34':
        provinceCode = 'ES-P';
        break;
      case '35':
        provinceCode = 'ES-GC';
        break;
      case '36':
        provinceCode = 'ES-PO';
        break;
      case '37':
        provinceCode = 'ES-SA';
        break;
      case '38':
        provinceCode = 'ES-TF';
        break;
      case '39':
        provinceCode = 'ES-S';
        break;
      case '40':
        provinceCode = 'ES-SG';
        break;
      case '41':
        provinceCode = 'ES-SE';
        break;
      case '42':
        provinceCode = 'ES-SO';
        break;
      case '43':
        provinceCode = 'ES-T';
        break;
      case '44':
        provinceCode = 'ES-TE';
        break;
      case '45':
        provinceCode = 'ES-TO';
        break;
      case '46':
        provinceCode = 'ES-V';
        break;
      case '47':
        provinceCode = 'ES-VA';
        break;
      case '48':
        provinceCode = 'ES-BI';
        break;
      case '49':
        provinceCode = 'ES-ZA';
        break;
      case '50':
        provinceCode = 'ES-Z';
        break;
      case '51':
        provinceCode = 'ES-CE';
        break;
      case '52':
        provinceCode = 'ES-ML';
        break;
      default:
        provinceCode = 'Código desconocido';
    }
    return provinceCode;
  }

}
