import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { UserService } from './services/user.service';
import {UserInfo} from "../models/user-info";

@Injectable()
export class OnboardedGuardService {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.canActivateProtectedRoutes$
      .pipe(tap(x => {
        if (!x) {
          if (localStorage.getItem('autoLogout') === 'true') {
            localStorage.removeItem('autoLogout');
            this.router.navigate(['/logout']);
          } else {
            this.router.navigateByUrl('/landing/true');
          }
        } else {
          this.userService.getUserInfo().subscribe((user) => {
            const onboarded = (user.subscriptionsHistory !== null && user.subscriptionsHistory !== undefined && user.subscriptionsHistory.length > 0)
              && (user.providerUserInfos && user.providerUserInfos.filter(
                (u) => u.ledgerToken !== null && u.iban !== null).length === 1);
            if (!onboarded && user != new UserInfo()) {
              this.router.navigate(['/onboarding']);
              return false;
            } else {
              return true;
            }
          });
        }
      }),
      );
  }
}
