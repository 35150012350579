<div *ngIf="!propertySelected">
  <nav class="navbar header-bar">
    <div class="columns is-fullwidth" [ngClass]="isMobile?'is-mobile pl-2': 'main_container_padding'">
      <div class="column has-text-centered" [ngClass]="isMobile?'is-two-thirds':'is-one-third'"
           style="align-self: center">
        <p class="has-text-weight-bold title has-text-white has-text-left	">
          {{'PRODUCTS_MODULE.PROPERTY' | translate}}
        </p>
      </div>
      <div class="column has-text-right"
           [ngClass]="isMobile?'is-one-third':'is-one-third is-offset-one-third'">
        <div class="main-services">
          <div class="main-frame columns">
            <div class="column">
              <button *ngIf="!showNewPropertyModal" class="main-servicesbutton" style="float: right"
                      (click)="openCreation()">
                <div class="main-content">
                  <p class="title is-block has-text-primary mb-0 has-text-right">
                    <fa-icon [icon]="faHouse"></fa-icon>
                  </p>
                  <p class="m-0 ">{{'NEW_TRANSFER.ADD_NEW' | translate}}</p>
                </div>
              </button>
              <button *ngIf="showNewPropertyModal" class="main-servicesbutton" style="float: right"
                      (click)="showNewPropertyModal = false">
                <div class="main-content">
                  <p class="subtitle is-block has-text-primary mb-0 has-text-right">
                    <fa-icon [icon]="faX"></fa-icon>
                  </p>
                  <p class="m-0 ">{{'CLOSE' | translate}}</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="transaction-page pt-4 ">
    <div class="main_container_padding">
      <div class="main_container_padding">
        <ng-template [ngIf]="showNewPropertyModal" [ngIfElse]="PropertiesList">
          <div class="box shadow">
            <!-- <form [formGroup]="propertyForm">
              <div class="columns is-multiline">
                <div class="column pb-0 is-one-third">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.PROPERTYTYPE' | translate }} </mat-label>
                    <mat-select formControlName="propertyType">
                      <mat-option value="0">{{ 'HOUSE' | translate }}</mat-option>
                      <mat-option value="1">{{ 'APARTMENT' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="propertyForm.get('propertyType')!.hasError('required')"
                      [innerHtml]="'VALIDATIONMESSAGES.PROPERTYTYPE_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="column pb-0 is-one-third">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.GARDEN' | translate }} </mat-label>
                    <mat-select formControlName="garden">
                      <mat-option value="true">{{ 'YES' | translate }}</mat-option>
                      <mat-option value="false">{{ 'NO' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="propertyForm.get('garden')!.hasError('required')"
                      [innerHtml]="'VALIDATIONMESSAGES.GARDEN_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="column pb-0 is-one-third">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.POOL' | translate }} </mat-label>
                    <mat-select formControlName="pool">
                      <mat-option value="true">{{ 'YES' | translate }}</mat-option>
                      <mat-option value="false">{{ 'NO' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="propertyForm.get('pool')!.hasError('required')"
                      [innerHtml]="'VALIDATIONMESSAGES.POOL_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="column pb-0 pt-0 is-half">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.BEDROOMS' | translate }} </mat-label>
                    <mat-select formControlName="bedrooms">
                      <mat-option value="">{{ '' | translate }}</mat-option>
                      <mat-option value="1">{{ '1' | translate }}</mat-option>
                      <mat-option value="2">{{ '2' | translate }}</mat-option>
                      <mat-option value="3">{{ '3' | translate }}</mat-option>
                      <mat-option value="4">{{ '4' | translate }}</mat-option>
                      <mat-option value="5">{{ '5' | translate }}</mat-option>
                      <mat-option value="6">{{ '6' | translate }}</mat-option>
                      <mat-option value="7">{{ '7' | translate }}</mat-option>
                      <mat-option value="8">{{ '8' | translate }}</mat-option>
                      <mat-option value="9">{{ '9' | translate }}</mat-option>
                      <mat-option value="10">{{ '10' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="propertyForm.get('bedrooms')!.hasError('required')"
                      [innerHtml]="'VALIDATIONMESSAGES.BEDROOMS_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="column pb-0 pt-0 is-half">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.SIZE' | translate }} </mat-label>
                    <input type="number" placeholder="0.00" step="10" matInput formControlName="size" />
                    <mat-error *ngIf="propertyForm.get('size')!.hasError('required')"
                      [innerHtml]="'VALIDATIONMESSAGES.SIZE_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form> -->
            <form [formGroup]="addressForm" (submit)="submitForm()">
              <div class="columns">
                <div class="column pb-0">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.STREETADDRESS' | translate }} </mat-label>
                    <input maxlength="255" type="text" id="autocomplete" matInput formControlName="streetAddress"/>
                    <mat-error *ngIf="addressForm.get('streetAddress')!.hasError('required')"
                               [innerHtml]="'VALIDATIONMESSAGES.STREETADDRESS_REQUIRED' | translate">
                    </mat-error>
                    <mat-error *ngIf="addressForm.get('streetAddress')!.hasError('maxlength')"
                               [innerHtml]="'VALIDATIONMESSAGES.MAXLENGTH40GENERIC' | translate">
                    </mat-error>
                    <mat-error *ngIf="addressForm.get('streetAddress')!.hasError('pattern')"
                               [innerHtml]="'VALIDATIONMESSAGES.ADDRESS_PATTERN' | translate">
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.OPTIONALADDRESS' | translate }} </mat-label>
                    <input type="text" matInput formControlName="address2"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.POSTALCODE' | translate }} </mat-label>
                    <input type="text" matInput formControlName="postalCode"/>
                    <mat-error *ngIf="addressForm.get('postalCode')!.hasError('invalidPostalCode')"
                               [innerHtml]="'VALIDATIONMESSAGES.PROPERTYPOSTALCODE' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="column pb-0">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.CITY' | translate }} </mat-label>
                    <input type="text" matInput formControlName="city"/>
                    <mat-error *ngIf="addressForm.get('city')!.hasError('required')"
                               [innerHtml]="'VALIDATIONMESSAGES.CITY_REQUIRED' | translate">
                    </mat-error>
                    <mat-error *ngIf="addressForm.get('city')!.hasError('maxlength')"
                               [innerHtml]="'VALIDATIONMESSAGES.MAXLENGTH40GENERIC' | translate">
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'PROFILEDATA.STATE' | translate }} </mat-label>
                    <mat-select formControlName="state">
                      <mat-option value="Álava/Araba">Álava/Araba</mat-option>
                      <mat-option value="Albacete">Albacete</mat-option>
                      <mat-option value="Alicante">Alicante</mat-option>
                      <mat-option value="Almería">Almería</mat-option>
                      <mat-option value="Asturias">Asturias</mat-option>
                      <mat-option value="Ávila">Ávila</mat-option>
                      <mat-option value="Badajoz">Badajoz</mat-option>
                      <mat-option value="Baleares">Baleares</mat-option>
                      <mat-option value="Barcelona">Barcelona</mat-option>
                      <mat-option value="Burgos">Burgos</mat-option>
                      <mat-option value="Cáceres">Cáceres</mat-option>
                      <mat-option value="Cádiz">Cádiz</mat-option>
                      <mat-option value="Cantabria">Cantabria</mat-option>
                      <mat-option value="Castellón">Castellón</mat-option>
                      <mat-option value="Ceuta">Ceuta</mat-option>
                      <mat-option value="Ciudad Real">Ciudad Real</mat-option>
                      <mat-option value="Córdoba">Córdoba</mat-option>
                      <mat-option value="Cuenca">Cuenca</mat-option>
                      <mat-option value="Gerona/Girona">Gerona/Girona</mat-option>
                      <mat-option value="Granada">Granada</mat-option>
                      <mat-option value="Guadalajara">Guadalajara</mat-option>
                      <mat-option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</mat-option>
                      <mat-option value="Huelva">Huelva</mat-option>
                      <mat-option value="Huesca">Huesca</mat-option>
                      <mat-option value="Jaén">Jaén</mat-option>
                      <mat-option value="La Coruña/A Coruña">La Coruña/A Coruña</mat-option>
                      <mat-option value="La Rioja">La Rioja</mat-option>
                      <mat-option value="Las Palmas">Las Palmas</mat-option>
                      <mat-option value="León">León</mat-option>
                      <mat-option value="Lérida/Lleida">Lérida/Lleida</mat-option>
                      <mat-option value="Lugo">Lugo</mat-option>
                      <mat-option value="Madrid">Madrid</mat-option>
                      <mat-option value="Málaga">Málaga</mat-option>
                      <mat-option value="Melilla">Melilla</mat-option>
                      <mat-option value="Murcia">Murcia</mat-option>
                      <mat-option value="Navarra">Navarra</mat-option>
                      <mat-option value="Orense/Ourense">Orense/Ourense</mat-option>
                      <mat-option value="Palencia">Palencia</mat-option>
                      <mat-option value="Pontevedra">Pontevedra</mat-option>
                      <mat-option value="Salamanca">Salamanca</mat-option>
                      <mat-option value="Segovia">Segovia</mat-option>
                      <mat-option value="Sevilla">Sevilla</mat-option>
                      <mat-option value="Soria">Soria</mat-option>
                      <mat-option value="Tarragona">Tarragona</mat-option>
                      <mat-option value="Tenerife">Tenerife</mat-option>
                      <mat-option value="Teruel">Teruel</mat-option>
                      <mat-option value="Toledo">Toledo</mat-option>
                      <mat-option value="Valencia">Valencia</mat-option>
                      <mat-option value="Valladolid">Valladolid</mat-option>
                      <mat-option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</mat-option>
                      <mat-option value="Zamora">Zamora</mat-option>
                      <mat-option value="Zaragoza">Zaragoza</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addressForm.get('state')!.hasError('required')"
                               [innerHtml]="'VALIDATIONMESSAGES.STATE_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>{{'PROFILEDATA.COUNTRY' | translate}}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option value="ES">{{'COUNTRIES.SPAIN' | translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addressForm.get('country')!.hasError('required')"
                               [innerHTML]="'ERROR_CODES.COUNTRY_IS_REQUIRED' | translate">
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <button type="submit" [disabled]="!addressForm.valid"
                      class="button is-block is-primary is-fullwidth is-medium mt-5">{{'NEXT' | translate}}</button>
              <br/>
            </form>
          </div>
        </ng-template>
        <ng-template #PropertiesList>
          <div class="division-line" *ngFor="let property of properties; index as i">
            <app-property [property]="property" [canDelete]="properties.length>1"
                          (reloadEvent)="getProperties()"></app-property>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div *ngIf="propertySelected">

</div>
