import { Directive, ElementRef } from '@angular/core';
import { Maskito } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';

@Directive({
    selector: '[maskedDate]'
})
export class MaskedDateDirective {

    options = maskitoDateOptionsGenerator({
        mode: 'dd/mm/yyyy',
        separator: '/',
    });
    constructor(private el: ElementRef) {
        new Maskito(this.el.nativeElement, this.options);
    }

}
