import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppError, AppErrorDetails, InternalError } from 'src/app/shared/models/app-errors';
import { BuenoWebApiResult, BuenoWebApiResultGeneric } from 'src/app/shared/models/bueno-api-response';
import { OAuthService } from 'angular-oauth2-oidc';
import { CreateBeneficiary } from '../../user/models/create-beneficiary';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { ConfirmBeneficiary } from '../../user/models/confirm-beneficiary';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {
  private url: string;

  constructor(private httpClient: HttpClient,
    private oAuth: OAuthService,
    @Inject('BASE_API_URL') private baseApiUrl: string,
    private baseApiService: BaseApiService) {
    this.url = `${this.baseApiUrl}`;
  }

  getBeneficiaries(search: any, startIndex: any, count: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    const params = new HttpParams().append('search', search)
      .append('startIndex', startIndex)
      .append('count', count);
    return this.httpClient.get<any>(`${this.url}/Beneficiaries`, { headers, params })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  createBeneficiary(beneficiary: CreateBeneficiary): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<any>(`${this.url}/beneficiaries`, beneficiary, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error, true))));
  }

  confirmBeneficiary(confirmBeneficiary: ConfirmBeneficiary): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<any>(`${this.url}/beneficiaries/confirm`, confirmBeneficiary, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error, true))));
  }

  deleteBeneficiary(beneficiaryId: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.delete<any>(`${this.url}/beneficiaries/${beneficiaryId}`, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  resendOTP(beneficiaryToken: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.post<any>(`${this.url}/beneficiaries/resend`, { beneficiaryToken }, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error, true))));
  }
}
