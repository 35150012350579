<div class="onboarding_wrapper">
  <div *ngIf="progress !== 100">
    <h3 class="title">
      <ng-container [ngSwitch]="currentStepName">
        <ng-container *ngSwitchCase="'EmailVerification'">
          {{ 'ONBOARDING.TITLE.0'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'Interest' || 'SpanishAddress' || 'ResidentialAddress'">
          {{ 'ONBOARDING.TITLE.1'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'noBanking'">
          {{ 'ONBOARDING.TITLE.2'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'idPassport' || 'Nie'">
          {{ 'ONBOARDING.TITLE.3'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'Financial'">
          {{ 'ONBOARDING.TITLE.4'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'PhoneVerification'">
          {{ 'ONBOARDING.TITLE.5'|translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'Kyc' || 'KycPending'">
          {{ 'ONBOARDING.TITLE.6'|translate }}
        </ng-container>
      </ng-container>
    </h3>
    <h4 class="subtitle" *ngIf="currentStepName === 'Kyc'">{{ 'ONBOARDING.SUBTITLE'|translate }}</h4>
    <div style="position: relative" class="main_container_padding">
      <mat-progress-bar class="progressbar" mode="determinate" [value]="progress">
      </mat-progress-bar>
    </div>
  </div>
  <div class="main_container_padding" [ngSwitch]="currentStepName"
    [ngClass]="currentStepName==='KycPending'?'main_container_padding':''">
    <div *ngSwitchCase="'EmailVerification'">
      <app-confirm-email></app-confirm-email>
    </div>
    <div *ngSwitchCase="'Interest'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="true" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="this.lastFilledStep === 1" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>
      <div *ngIf="showEmailNotification" class="notification is-success">
        {{ 'EMAILVERIFIEDEXP' | translate }}
        <button class="delete" (click)="showEmailNotification = false">

        </button>
      </div>
      <app-user-preferences [userSettings]="false"></app-user-preferences>
    </div>
    <div *ngSwitchCase="'SpanishAddress'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="this.lastFilledStep === 2" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>

      <app-property-settings [propertyAddress]="user.legalAddress" [userSettings]="false"></app-property-settings>
    </div>
    <div *ngSwitchCase="'ResidentialAddress'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="this.lastFilledStep === 3" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>
      <app-address [residentialAddress]="user.address" [userSettings]="false"></app-address>
    </div>
    <div *ngSwitchCase="'noBanking'">
      <p class="title is-size-4 m-6">{{ 'ONBOARDING.WAITING_FOR_NO_BANKING' | translate }}</p>
      <p class="title is-size-4 m-6" [innerHTML]="'ONBOARDING.WAITING_FOR_NO_BANKING_OPEN_BANK' | translate"></p>
      <div class="columns custom-columns main_container_padding">
        <div class="column">
          <button (click)="goPreviousStep()" class="button is-block is-outlined is-fullwidth is-medium round-button">
            {{ 'INSURANCE.PROPOSAL.REVIEW.BACK'|translate }}
          </button>
        </div>
        <div class="column">
          <button (click)="changeBankingPreference()"
            class="button is-block is-fullwidth is-primary is-medium round-button">
            {{ 'ONBOARDING.WAITING_FOR_NO_BANKING_OPEN_BANK_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'idPassport'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="this.lastFilledStep === 5" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>
      <app-id-registration [countryCode]="user.nationality !== null ? user.nationality : user.countryCode"
        [birthday]="user.birthday" [birthCountry]="user.birthCountry" [gender]="user.gender"
        [phoneCountryCode]="user.phoneCountryCode" [identifications]="user.identificationInfo"
        [files]="user.uploadedDocuments">
      </app-id-registration>
    </div>
    <div *ngSwitchCase="'Nie'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="this.lastFilledStep === 6" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>
      <app-nie [identifications]="user.identificationInfo" [files]="user.uploadedDocuments"></app-nie>
    </div>
    <div *ngSwitchCase="'Financial'">
      <div class="columns pt-2" style="display: flex;">
        <div class="column is-1 is-half-mobile">
          <button class="button is-primary is-rounded" (click)="goPreviousStep()">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
          </button>
        </div>
        <div class="column is-1 is-offset-10 is-half-mobile">
          <button class="button is-primary is-rounded" [disabled]="true" (click)="goNextStep()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </div>
      <app-user-information [userInfo]="user"></app-user-information>
    </div>
    <div *ngSwitchCase="'PhoneVerification'">
      <app-confirm-phone></app-confirm-phone>
    </div>
    <div *ngSwitchCase="'Kyc'">
      <div class="columns pt-2" *ngIf="showPhoneNotification">
        <div class="column">
          <div id="SuccessNotification" class="notification is-success">
            {{ 'MOBILEPHONECONFIRMEDEXP' | translate }}
            <button class="delete" (click)="showPhoneNotification = false">

            </button>
          </div>
        </div>
      </div>
      <app-kyc-process></app-kyc-process>
    </div>
    <div *ngSwitchCase="'KycPending'">
      <div>
        <p class="title is-size-4 mt-5" *ngIf="kycStatus=='VGR'||kycStatus=='PVE'||kycStatus=='FVE'||kycStatus=='FVA'">
          {{ 'ONBOARDING.KYC_STATUS.' + kycStatus|translate }}</p>

        <div class="main_container_padding pt-4"
          *ngIf="kycStatus=='EGV'||kycStatus=='EPV'||kycStatus=='EVA'||kycStatus=='ERR'">
          <article class="message is-danger has-text-centered">
            <div class="message-header has-text-centered" style="display: block">
              <p>{{ 'ONBOARDING.KYC_STATUS.' + kycStatus|translate }}</p>
            </div>
            <div class="message-body has-text-centered">
              {{ 'ONBOARDING.KYC_STATUS_ERRORS.' + kycStatus|translate }}
            </div>
          </article>
        </div>
        <p class="title is-size-4 mt-5"
          *ngIf="!(kycStatus=='VGR'||kycStatus=='PVE'||kycStatus=='FVE'||kycStatus=='FVA'||kycStatus=='EGV'||kycStatus=='EPV'||kycStatus=='EVA'||kycStatus=='ERR')">
          {{ 'ONBOARDING.KYC_INITIALIZED'|translate }}</p>
      </div>
      <!-- <button (click)="this.currentStep = 6" style="margin:auto"
              class="button is-block is-primary is-medium mt-5">{{'RESTART' |
              translate}}</button> -->
    </div>
    <div *ngSwitchCase="'Subscription'">
      <p class="title is-size-4">{{ 'ONBOARDING.ENDING_TITLE'|translate }} </p>
      <!-- <p class="subtitle is-size-6">{{'ONBOARDING.ENDING_SUBTITLE'|translate}}</p> -->
      <app-subscription-plans [reference]="this"></app-subscription-plans>
    </div>
    <div *ngSwitchCase="'AccountCreation'">
      <p class="title is-size-4" style="margin:auto">{{ 'ONBOARDING.OPENACCOUNTINFO_1' | translate }}</p>
      <p class="title is-size-4" style="margin:auto" [innerHTML]="'ONBOARDING.OPENACCOUNTINFO_2' | translate">
      </p>
      <button (click)="createAccount()" style="margin:auto" class="button is-block is-primary is-medium mt-5">
        {{ 'ONBOARDING.ORDEROPENACCOUNT' | translate }}
      </button>
    </div>
  </div>
</div>