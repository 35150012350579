import { FormGroup } from '@angular/forms';

export function ValidMinNumber(controlName: string, number: number): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.value == null || control.value === '') {
            return;
        } else {
            /// check if the amount is lower or equal than the balance and greater than 0
            if (control.value < number) {
                control.setErrors({ min: true });
            } else {
                control.setErrors(null);
            }
        }
    };
}
