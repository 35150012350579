/// create an angular directive to avoid enter key behavior on form inputs
import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFormInput]'
})
export class FormInputDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.addEventListener('keydown', (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        return false;
      }
      return true;
    });
  }

}
