import {Component} from '@angular/core';
import {faArrowLeft, faCheck, faCircle, faCreditCard, faSpinner, faUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {UserService} from "../core/services/user.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ValidAmount} from "../shared/validators/valid-amount";
import {ConfirmTransfer} from "../user/models/confirm-transfer";
import {TransfersService} from "../core/services/transfers.service";
import {ValidMinNumber} from "../shared/validators/valid-min-number";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {

  step = 0;
  user: any;
  loaded: boolean = false;
  funding: boolean = false;
  isMobile = window.innerWidth <= 770;
  fundForm = this.formBuilder.group({
    amount: [, { validators: [Validators.required], updateOn: 'change' }]
  });

  confirmCodeForm = this.formBuilder.group({
    otpCode: ['', { validators: [Validators.required], updateOn: 'change' }]
  });
  balance: any;
  faCircle = faCircle;
  faCreditCard = faCreditCard;
  ableToFund: boolean = false;
  disableInputs: any;
  faArrowLeft = faArrowLeft;
  self = self;
  faCheck = faCheck;
  faSpinner = faSpinner;
  operationId: any;
  showOtpForm: boolean = false;
  cardError: any;
  fundingComplete: boolean = false;
  number: any;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private transfersService: TransfersService
  ) {
  }

  ngOnInit(): void {
    this.userService.getUserInfo().subscribe((details) => {
      this.user = details;
    });
    this.getCards();
    this.userService.getBalance().subscribe((balanceInfo) => {
      this.balance = balanceInfo.availableBalance;
      this.fundForm.addValidators([ValidAmount('amount', this.balance), ValidMinNumber('amount', 50), Validators.required]);
      this.fundForm.updateValueAndValidity();
    });
  }

  onOTPCodeCompleted(code: string): void {
    this.confirmCodeForm.controls['otpCode'].setValue(code);
  }

  getCards(): void {
    this.userService.getCards().subscribe((cards) => {
      if (cards.length > 0) {
        this.step = 2;
        this.loaded = true;
        if (cards.filter((x: any) => x.status != 100 && x.status != 99).length > 0) {
          this.ableToFund = true;
          this.funding = true;
          this.number = cards.filter((x: any) => x.status != 100 && x.status != 99)[0].number;
        }
      } else {
        this.userService.checkCardsRequeriments().subscribe((result) => {
          this.step = 1;
          this.loaded = true;
        }, (error) => {
          this.cardError = error.error;
          this.step = -1;
          this.loaded = true;
        });
      }
    });
  }

  createCard(): void {
    this.userService.createCard().subscribe((card) => {
      this.step = 2;
    });
  }

  fundCard(): void {
    if (this.fundForm.valid) {
      this.userService.fundCard(this.fundForm.value.amount).subscribe((card) => {
        this.fundForm.controls['amount'].disable();
        this.operationId = card.operationId;
        this.showOtpForm = card.isScaRequired === "S";
        if (!this.showOtpForm) {
          let confirmTransfer = new ConfirmTransfer();
          confirmTransfer.operationId = this.operationId;
          confirmTransfer.isCardLoadTransfer = true;
          this.transfersService.confirmTransfer(confirmTransfer).subscribe((result) => {
            this.fundingComplete = true;

          });
        } else {
          this.step = 2;
        }
      });
    }

  }

  confirmTransfer(): void {
    if (this.confirmCodeForm.valid) {
      let confirmTransfer = new ConfirmTransfer();
      confirmTransfer.operationId = this.operationId;
      confirmTransfer.code = this.confirmCodeForm.value.otpCode ? this.confirmCodeForm.value.otpCode : '';
      confirmTransfer.isCardLoadTransfer = true;
      this.transfersService.confirmTransfer(confirmTransfer).subscribe((result) => {
        this.fundingComplete = true;
        this.fundForm.controls['amount'].enable();
      });
    }
  }

}


