import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { KycService } from '../../core/services/kyc.service';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-kyc-process',
  templateUrl: './kyc-process.component.html',
  styleUrls: ['./kyc-process.component.scss'],
})
export class KycProcessComponent implements OnInit {
  environment = environment;
  user: any;
  constructor(
    private kycService: KycService,
    private userService: UserService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.userService.getUserInfo().subscribe((user) => {
      this.user = user;
    });
  }

  startKyc(): void {
    const identification = this.user.identificationInfo.find(
      (x: { type: string; }) => x.type !== 'NIE'
    );
    const providedType = identification.type;
    const kycModel = {
      successUrl: `${environment.appBaseUrl}?kyc=true`,
      failureUrl: `${environment.appBaseUrl}`,
      communicationUrl: `${environment.apiBaseUrl}/kyc/status`,
    };
    this.kycService.initializeKyc(kycModel).subscribe((val) => {
      setTimeout(() => {
        window.open(val.url, '_blank');
      });
    });
  }
  startKycSms(): void {
    const identification = this.user.identificationInfo.find(
      (x: { type: string; }) => x.type !== 'NIE'
    );
    const providedType = identification.type;
    const kycModel = {
      successUrl: `${environment.appBaseUrl}?kyc=true`,
      failureUrl: `${environment.appBaseUrl}`,
      communicationUrl: `${environment.apiBaseUrl}/kyc/status`,
    };
    this.kycService.initializeKycSms(kycModel).subscribe((val) => {

    });
  }
  simulateKycVerification(): void {
    this.kycService.simulateKycVerification().subscribe((val) => {
      this.userService.updateUserInfo().subscribe((user) => {
        this.router.navigate(['']);
      });
    });
  }
}
