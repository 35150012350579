import { FormGroup } from '@angular/forms';

export function ValidAmount(controlName: string, balance: number): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.value == null || control.value === '') {
            return;
        } else {
            /// check if the amount is lower or equal than the balance and greater than 0
            if (control.value > balance) {
                control.setErrors({ invalidAmount: true });
            } else {
                const currentErrors = control.errors ? { ...control.errors } : null;
                const newErrors = currentErrors ? { ...currentErrors } : null;
                if (newErrors) {
                    delete newErrors['invalidAmount'];
                }
                control.setErrors(newErrors);
            }
        }
    };
}
