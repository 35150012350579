import { AfterContentInit, Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';
import { IdentificationInfo } from 'src/app/models/user-info';
import { ValidDate } from 'src/app/shared/validators/valid-date';
import { Country } from '@angular-material-extensions/select-country';
import { allowedCountries } from 'src/app/shared/allowedCountries';
import { ValidAge } from 'src/app/shared/validators/valid-age';
import { ValidDNI } from 'src/app/shared/validators/valid-dni';

@Component({
  selector: 'app-id-registration',
  templateUrl: './id-registration.component.html',
  styleUrls: ['./id-registration.component.scss'],
})
export class IdRegistrationComponent implements AfterContentInit {

  faTimes = faTimes;
  allowDNI: any;
  allowID: any;
  allowPassport: any;
  allowESRESCARD: any;
  defaultNationality: any;
  passportCountry: Country;
  isMobile = window.innerWidth <= 770;
  @Input() countryCode!: string;
  @Input() identifications!: IdentificationInfo[];
  @Input() birthCountry!: string;
  @Input() birthday!: string;
  @Input() gender!: string;
  @Input() files: any;
  @Input() phoneCountryCode!: string;
  allowedCountries = allowedCountries;
  buttonBlocked!: boolean;
  successfullyUploaded!: boolean;
  editMode!: boolean;
  private readonly EU_COUNTRIES = new Set([
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR',
    'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL',
    'PT', 'RO', 'SK', 'SI', 'SE', 'CH', 'GB'
  ]);

  private readonly RESTRICTED_COUNTRIES = new Set([
    'BY', 'CU', 'ER', 'IR', 'KP', 'RU', 'SY', 'VE'
  ]);
  nationalityForm = this.formBuilder.group({
    countryCode: ['', { validators: [Validators.required], updateOn: 'change' }],
  });
  entityTypeForm = this.formBuilder.group(
    {
      entityType: ['', { validators: [Validators.required], updateOn: 'change' }],
      birthdate: ['', { validators: [Validators.required], updateOn: 'change' }],
      gender: ['', { validators: [Validators.required], updateOn: 'change' }],
    },
    {
      validators: [ValidAge('birthdate')],
    }
  );
  passportForm = this.formBuilder.group(
    {
      passportNumber: ['', { validators: [Validators.required], updateOn: 'change' },],
      issuingCountry: ['', { validators: [Validators.required], updateOn: 'change' },],
      expirationDate: ['', { validators: [Validators.required], updateOn: 'change' },],
    },
    {
      validators: [ValidDate('expirationDate', true, false)],
    }
  );
  dniForm = this.formBuilder.group(
    {
      dniNumber: ['', { validators: [Validators.required], updateOn: 'change' }],
      expirationDate: ['', { validators: [Validators.required], updateOn: 'change' },],
    },
    {
      validators: [ValidDate('expirationDate', true, false), ValidDNI('dniNumber')],
    }
  );
  skipNationalityChange!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
  ) {
    this.defaultNationality = this.passportCountry = {
      name: '',
      alpha2Code: '',
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };
  }


  ngAfterContentInit(): void {
    this.autoCompleteFields();
    this.passportForm.statusChanges.subscribe((x: any) => {
      if (x === 'VALID') {
        this.buttonBlocked = false;
      }
    });
    this.dniForm.statusChanges.subscribe((x: any) => {
      if (x === 'VALID') {
        this.buttonBlocked = false;
      }
    });
  }


  autoCompleteFields(): void {
    if (this.countryCode !== undefined) {
      this.defaultNationality = this.allowedCountries.filter(
        (x: any) => x.alpha2Code === this.countryCode
      )[0];
      if (this.gender !== null) {
        this.entityTypeForm.controls['gender'].patchValue(this.gender);
      }
      if (this.birthday !== null) {
        this.entityTypeForm.controls['birthdate'].patchValue(new Date(this.birthday));
      }
      this.onNationalityChange(this.defaultNationality);
      if (
        this.identifications !== undefined &&
        this.identifications.length > 0
      ) {
        this.editMode = true;
        this.identifications.forEach((id) => {
          switch (id.type) {
            case 'ID':
              if (id.issuingCountry === 'ES') {
                this.entityTypeForm.controls['entityType'].patchValue('DNI');
                this.dniForm.controls['dniNumber'].patchValue(id.value);
                this.dniForm.controls['expirationDate'].patchValue(
                  new Date(id.expirationDate)
                );
              } else {
                this.entityTypeForm.controls['entityType'].setValue('ID');
                this.skipNationalityChange = true;
                this.passportForm.patchValue({
                  passportNumber: id.value,
                  issuingCountry: id.issuingCountry,
                  expirationDate: new Date(id.expirationDate)
                });

              }
              break;
            case 'ES_RESIDENCY_CARD':
              this.entityTypeForm.controls['entityType'].setValue('ES_RESIDENCY_CARD');
              this.dniForm.controls['dniNumber'].patchValue(id.value);
              this.dniForm.controls['expirationDate'].patchValue(
                new Date(id.expirationDate)
              );
              break;
            case 'PASSPORT_NUMBER':
              this.entityTypeForm.controls['entityType'].patchValue('PASSPORT_NUMBER');
              this.skipNationalityChange = true;
              this.passportForm.patchValue({
                passportNumber: id.value,
                issuingCountry: id.issuingCountry,
                expirationDate: new Date(id.expirationDate)
              });
              break;
            default:
              break;
          }
        });
      }
    } else if (this.phoneCountryCode !== undefined) {
      this.defaultNationality = this.allowedCountries.filter(
        (x: any) => x.callingCode === `+${this.phoneCountryCode}`
      )[0];
      this.nationalityForm.controls['countryCode'].patchValue(this.defaultNationality.alpha2Code);
      this.onNationalityChange(this.defaultNationality);

    }
  }

  onNationalityChange(country: Country): void {
    if (country && !this.skipNationalityChange) {
      this.resetFlagsAndForms();
      this.nationalityForm.controls.countryCode.patchValue(country.alpha2Code);
      if (country.alpha2Code === 'ES') {
        this.handleESNationality();
      } else if (this.EU_COUNTRIES.has(country.alpha2Code)) {
        this.handleEUNationality(country);
      } else if (this.RESTRICTED_COUNTRIES.has(country.alpha2Code)) {
        this.handleRestrictedNationality(country);
      } else {
        this.handleDefaultNationality(country);
      }
    } else {
      this.skipNationalityChange = false;
    }
  }

  private resetFlagsAndForms(): void {
    this.allowDNI = this.allowESRESCARD = this.allowID = this.allowPassport = false;
    if (!this.editMode) {
      this.entityTypeForm.controls['entityType'].patchValue('');
      this.dniForm.reset();
      this.passportForm.reset();
    }
  }

  private handleESNationality(): void {
    this.allowDNI = true;
    this.entityTypeForm.controls['entityType'].patchValue('DNI');
  }

  private handleEUNationality(country: Country): void {
    this.allowID = this.allowPassport = this.allowESRESCARD = true;
    this.passportForm.controls['issuingCountry'].patchValue(country.alpha2Code);
    this.passportCountry = country;
  }

  private handleRestrictedNationality(country: Country): void {
    this.allowESRESCARD = true;
    this.entityTypeForm.controls['entityType'].patchValue('ES_RESIDENCY_CARD');
    this.passportCountry = country;
  }

  private handleDefaultNationality(country: Country): void {
    this.allowESRESCARD = this.allowPassport = true;
    this.passportForm.controls['issuingCountry'].patchValue(country.alpha2Code);
    this.passportCountry = country;
  }

  onPassportCountryChange(country: Country): void {
    this.passportForm.controls['issuingCountry'].patchValue(country.alpha2Code);
  }

  submitForm(): void {
    let identification = new IdentificationInfo();
    switch (this.entityTypeForm.value.entityType) {
      case 'DNI':
      case 'ES_RESIDENCY_CARD':
        if (this.dniForm.valid) {
          identification.expirationDate = this.dniForm.value.expirationDate;
          identification.issuingCountry = 'ES';
          identification.type =
            this.entityTypeForm.value.entityType === 'DNI'
              ? 'ID'
              : 'ES_RESIDENCY_CARD';
          identification.value = this.dniForm.value.dniNumber.toUpperCase().replace(/[^a-zA-Z0-9  -]/g, '');
        }
        break;
      case 'ID':
      case 'PASSPORT_NUMBER':
        if (this.passportForm.valid) {
          identification.expirationDate = this.passportForm.value.expirationDate;
          identification.issuingCountry =
            this.passportForm.value.issuingCountry;
          identification.value =
            this.passportForm.value.passportNumber.toUpperCase().replace(/[^a-zA-Z0-9  -]/g, '');
          identification.type =
            this.entityTypeForm.value.entityType === 'ID'
              ? 'ID'
              : 'PASSPORT_NUMBER';
        }
        break;
    }
    const birthdate = this.entityTypeForm.value.birthdate;
    if (identification !== new IdentificationInfo()) {
      this.buttonBlocked = true;
      this.userService.setPersonalDetails(this.nationalityForm.value.countryCode!, birthdate, this.entityTypeForm.value.gender, this.nationalityForm.value.countryCode!).subscribe((res) => {
        this.userService.setIdentificationDetails(identification).subscribe((x: any) => {
          if (this.editMode) {
            localStorage.setItem('lastEditedStep', 'idPassport');
          }
          this.router.navigate(['']);
        });
      });
    }
  }


}


