import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faAnalytics,
  faArrowAltCircleRight,
  faArrowRight, faBars, faCircle,
  faCoins,
  faCopy,
  faCreditCard
} from '@fortawesome/pro-solid-svg-icons';
import { UserService } from 'src/app/core/services/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../../../core/auth.service';
import { UserBankingAccount } from 'src/app/shared/models/user-banking-account';
import { Router } from '@angular/router';
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'app-available-balance-box',
  templateUrl: './available-balance-box.component.html',
  styleUrls: ['./available-balance-box.component.scss']
})
export class AvailableBalanceBoxComponent {
  totalAmount = '0';
  currency = 'EUR';
  faAnalytics = faAnalytics;
  faArrowRight = faArrowRight;
  faArrowAltCircleRight = faArrowAltCircleRight;
  faCoins = faCoins;
  @Output() finishedLoading = new EventEmitter<boolean>();
  @Input() accounts!: UserBankingAccount[];
  isAuthenticated = false;
  isLoaded = false;
  isMobile = window.innerWidth <= 770;
  userInfo: any;
  name!: string;
  iban!: string;

  constructor(private userService: UserService,
    private authService: AuthService,
    private oAuth: OAuthService,
    private router: Router,
    private clipboard: Clipboard) {
    this.authService.isAuthenticated$.subscribe((res) => {
      if (this.isAuthenticated !== res) {
        this.isAuthenticated = res;
        if (res) {
          this.userService.getUserInfo().subscribe((userInfo) => {
            if (userInfo && userInfo.providerUserInfos) {
              this.userInfo = userInfo;
              this.name = userInfo.firstName + (userInfo.middleName ? ' ' + userInfo.middleName.charAt(0) + '.' : '') + ' ' + userInfo.lastName;
              if (userInfo.providerUserInfos.filter(
                (u) => u.ledgerToken !== null && u.iban !== null).length === 1) {
                this.getBalance();
                this.iban = userInfo.providerUserInfos.filter((u) => u.ledgerToken !== null && u.iban !== null)[0].iban;
              }
            }
          });
        }
      }
    });
  }

  getBalance(): void {
    this.userService.getBalance().subscribe((balanceInfo) => {
      this.totalAmount = balanceInfo.realBalance;
      this.currency = balanceInfo.currencyCode;
      this.isLoaded = true;
    });
  }

  copyIban(): void {
    this.clipboard.copy(this.iban);
  }

  protected readonly faCreditCard = faCreditCard;
  protected readonly faBars = faBars;
  protected readonly faCircle = faCircle;
  protected readonly faCopy = faCopy;
}
