import {AuthConfig} from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.identityServerUrl,
  redirectUri: window.location.origin,
  clientId: 'BuenoBankClient',
  responseType: 'code',
  sessionChecksEnabled: true,
  scope: 'openid testApi offline_access',
  showDebugInformation: true,
  tokenEndpoint: environment.identityServerUrl + '/connect/token',
  oidc: true,
  requestAccessToken: true,
  requireHttps: 'remoteOnly',
  disablePKCE: false,
  postLogoutRedirectUri: window.location.origin,
};
