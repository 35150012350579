<div class="availableBalanceBox shadow is-fullheight is-align-content-center	">
  <div class="main-container">
    <div class="main-main">
      <div class="main-frame1000001824">
        <div class="main-frame1000001842">
          <div class="has-text-centered is-fullwidth" *ngIf="!isMobile">
            <img src="assets/img/B-no-bg.png" class="main-image" />
          </div>
          <div class="main-frame1000001817"> 
            <span class="main-text StandartText16|Bold my-4">
              <span>{{name}}</span>
            </span>
          </div>
          <div class="main-frame1000001818">
            <div class="frame1000001832-frame1000001833">
              <div class="frame1000001832-group1000001831">
                <img src="assets/svg-country-flags/svg/eu.svg" alt="Star22382" class="frame1000001832-star2" />
              </div>
              <span class="frame1000001832-text5">
                <span>{{ iban|iban }}</span>
              </span>
              <a (click)="copyIban()" class="ml-2">
                <fa-icon [icon]="faCopy" class="white-icon"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-frame1000001804">
        <div class="main-frame1000001841">
          <div class="main-group1000001795">
            <span class="main-text04 my-4"><span>{{'PRODUCTS_MODULE.AVAILABLE_BALANCE' | translate}}</span></span>
          </div>
        </div>
        <div class="main-price" *ngIf="isLoaded">
          <span class="main-text06"><span>{{(totalAmount | number: '1.2-2')?.replaceAll(',', ' ')}} {{currency === 'EUR'
              ? '€' : currency}}</span></span>

        </div>
      </div>
      <div class="main-services">
        <div class="main-frame columns">
          <div class="column">
            <button class="main-servicesbutton" routerLink="/transactions/new-transfer">
              <div class="main-content">
                <p class="subtitle is-block has-text-primary mb-0">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faArrowRight" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0">{{'NEW_TRANSFER.SEND_MONEY_LABEL' | translate}}</p>
              </div>
            </button>
          </div>
          <div class="column">
            <button class="main-servicesbutton" routerLink="/transactions/top-up-bank">
              <div class="main-content">
                <p class="subtitle is-block has-text-primary mb-0">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faCoins" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0">{{'NEW_TRANSFER.TOP_ACCOUNT_LABEL' |
                  translate}}</p>
              </div>
            </button>
          </div>
        </div>
        <div class="main-frame columns">
          <div class="column">
            <button class="main-servicesbutton" routerLink="/cards">
              <div class="main-content">
                <p class="subtitle is-block has-text-primary mb-0">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faCreditCard" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0 ">{{'CARDS' | translate}}</p>
              </div>
            </button>
          </div>
          <div class="column">
            <button class="main-servicesbutton" routerLink="/account">
              <div class="main-content">
                <p class="subtitle is-block has-text-primary mb-0">
                  <fa-stack>
                    <fa-icon [icon]="faCircle" [stackItemSize]="'2x'"></fa-icon>
                    <fa-icon [icon]="faBars" [stackItemSize]="'1x'" [inverse]="false"></fa-icon>
                  </fa-stack>
                </p>
                <p class="m-0 ">{{'MORE' | translate}}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="columns mb-0">
  <div *ngFor="let account of accounts.slice(0,2)">
    <button class="button is-light p-0" style="background: white;" [disabled]="account.oldAccount" routerLink="/transactions/overview">
      <span *ngIf="account.currency === 'EUR'" class="ibanNumber">
        <img style="width: 30px; vertical-align: middle;" src="assets/img/EU.svg" />
        {{account.currency}} {{account.ibanNumber |iban}} {{account.oldAccount ? '(old)' : ''}}
      </span>
    </button>
  </div> -->
  </div>