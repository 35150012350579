import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { ValidDNI } from "../../shared/validators/valid-dni";
import { IdentificationInfo } from "../../models/user-info";

@Component({
  selector: 'app-nie',
  templateUrl: './nie.component.html',
  styleUrls: ['./nie.component.scss']
})
export class NieComponent implements AfterContentInit {
  pdfSrc: any;
  @Input() identifications!: IdentificationInfo[];
  @Input() files: any;
  showPreview!: boolean;
  enableUpload!: boolean;
  hasFileLoaded!: boolean;
  validDate!: boolean;
  uploadingImage!: boolean;
  successfullyDeleted!: boolean;
  openPdf!: File;
  selectedFile: any;
  srcResult: any;
  fileName!: string;
  @ViewChild('imageInput') imageInput!: ElementRef;
  successfullyUploaded!: boolean;
  nieForm = this.formBuilder.group({
    taxIdNumber: ['', { validators: [Validators.required], updateOn: 'change' },],
    taxIdIssuingMonth: ['', { validators: [Validators.required], updateOn: 'change' },],
    taxIdIssuingYear: ['', { validators: [Validators.required], updateOn: 'change' },],
    taxIdImage: ['', { validators: [Validators.required], updateOn: 'change' }],
  },
    { validators: [, ValidDNI('taxIdNumber')] })
  protected readonly faSpinner = faSpinner;
  editMode: any;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService) {
  }

  public get zoomSetting() {
    return String('page-fit');
  }

  public set zoomSetting(value) {
  }

  ngAfterContentInit(): void {
    this.autoCompleteFields();
    this.nieForm.get('taxIdIssuingMonth')?.valueChanges.subscribe((x: any) => {
      if (x !== null && this.nieForm.get('taxIdIssuingYear')?.value !== null && this.checkValidDate(x, this.nieForm.get('taxIdIssuingYear')?.value)) {
        this.validDate = true;
        this.nieForm.controls['taxIdIssuingMonth']?.setErrors(null);
      } else {
        this.validDate = false;
        this.nieForm.controls['taxIdIssuingMonth']?.setErrors({ invalidDate: true });
      }
    });
    this.nieForm.get('taxIdIssuingYear')?.valueChanges.subscribe((x: any) => {
      if (x !== null && this.nieForm.get('taxIdIssuingMonth')?.value !== null && this.checkValidDate(this.nieForm.get('taxIdIssuingMonth')?.value, x)) {
        this.validDate = true;
        this.nieForm.controls['taxIdIssuingMonth']?.setErrors(null);
      } else {
        this.validDate = false;
        this.nieForm.controls['taxIdIssuingMonth']?.setErrors({ invalidDate: true });
      }
    });
  }

  onFileSelected(e: any, documentType: any): void {
    this.successfullyUploaded = false;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      if (file.size < 10485760) {
        this.fileName = file.name;
        this.nieForm.get('taxIdImage')?.setValue(file.name);
        this.uploadingImage = true;
        this.nieForm.get('taxIdImage')?.updateValueAndValidity();
        if (file.type === 'application/pdf') {
          const reader = new FileReader();
          this.openPdf = file;
          this.hasFileLoaded = true;
          reader.onload = (loadedFile) => {
            this.pdfSrc = loadedFile.target?.result;
            this.enableUpload = true;
          };
          reader.readAsArrayBuffer(file);
          this.uploadingImage = false;
        } else {
          let year = parseInt(this.nieForm.get('taxIdIssuingYear')?.value ?? '', 10);
          let month = parseInt(this.nieForm.get('taxIdIssuingMonth')?.value ?? '', 10);
          const date = new Date(year, month - 1, 1);
          this.userService.DocumentUpload(file, documentType, date).subscribe((x: any) => {
            this.successfullyUploaded = true;
            this.successfullyDeleted = false;
            this.uploadingImage = false;
            this.submitForm();
          });
        }
      } else {
        this.nieForm.get('taxIdImage')?.setErrors({ invalidSize: true });
      }
    }
  }

  showPreviewFunction(): void {
    this.showPreview = true;
    const me = this;
    const fileInput = document.getElementById('file') as HTMLInputElement;
    fileInput.click();
  }

  closePreview(): void {
    this.showPreview = false;
    const fileInput = document.getElementById('file') as HTMLInputElement;
    this.pdfSrc = '';
    fileInput.value = "";
    this.hasFileLoaded = false;
  }

  uploadPDFImage(documentType: any): void {
    this.showPreview = false;
    // this.uploadingImage = true;
    let year = parseInt(this.nieForm.get('taxIdIssuingYear')?.value ?? '', 10);
    let month = parseInt(this.nieForm.get('taxIdIssuingMonth')?.value ?? '', 10);
    const date = new Date(year, month - 1, 1);
    this.userService.DocumentUpload(this.openPdf, 'NIE', date).subscribe((x: any) => {
      this.successfullyUploaded = true;
      this.showPreview = false;
      this.successfullyDeleted = false;
      this.submitForm();
    });
  }

  removeAllFiles(): void {
    this.uploadingImage = true;
    this.userService.removeAllFiles().subscribe((x: any) => {
      this.uploadingImage = false;
      this.successfullyUploaded = false;
      this.successfullyDeleted = true;
      this.closePreview();
    },
      (error) => {
        this.uploadingImage = false;
        this.successfullyUploaded = false;
        this.successfullyDeleted = true;
      });
  }

  checkValidDate(month: any, year: any): boolean {
    // check if the date is before today
    if (month !== '' && year !== '') {
      const today = new Date();
      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();
      if (parseInt(year) < todayYear) {
        return true;
      } else if (parseInt(year) === todayYear && parseInt(month) <= todayMonth) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  submitForm() {
    let identification = new IdentificationInfo();
    identification.type = 'NIE';
    identification.value = this.nieForm.value.taxIdNumber.toUpperCase().replace(/[^a-zA-Z0-9  -]/g, '');
    identification.expirationDate = new Date(this.nieForm.value.taxIdIssuingYear, this.nieForm.value.taxIdIssuingMonth - 1, 1);
    identification.issuingCountry = 'ES';
    this.userService.setIdentificationDetails(identification).subscribe((x: any) => {
      if (this.editMode) {
        localStorage.setItem('lastEditedStep', 'Nie');
      }
      this.router.navigate(['']);
    });

  }

  private autoCompleteFields() {
    let nie = this.identifications.find(x => x.type === 'NIE');
    if (nie !== undefined) {
      this.editMode = true;
      this.nieForm.get('taxIdNumber')?.setValue(nie.value);
      let month = (new Date(nie.expirationDate).getMonth() + 1).toString();
      let year = (new Date(nie.expirationDate).getFullYear()).toString();
      this.nieForm.get('taxIdIssuingMonth')?.setValue(month);
      this.nieForm.get('taxIdIssuingYear')?.setValue(year);
      this.validDate = true;
    }
    if (this.files !== undefined && this.files.length > 0) {
      this.successfullyUploaded = true;
      this.nieForm.get('taxIdImage')?.setValue(this.files[0].fileId);
    }
  }
}
