import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.canActivateProtectedRoutes$
      .pipe(tap(x => {
        if (!x) {
          if (localStorage.getItem('autoLogout') === 'true') {
            localStorage.removeItem('autoLogout');
            this.router.navigate(['/logout']);
          } else {
            this.router.navigateByUrl('/landing/true');
          }
          return x;
        } else {
          return x;
        }
      }));
  }
}
