export class UserBankingAccount {
    accountId!: string;
    type!: string;
    accountName!: string;
    ibanNumber!: string;
    currency!: string;
    balanceAmount!: number;
    bankCountryCode!: string;
    routingCode!: string;
    routingCodeType!: string;
    oldAccount!: boolean;
}
