import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function ValidAge(controlName: string): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        const today = new Date();
        const dateToCompare = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

        if (!control.value){
            return;
        }


        const day = control.value.getDate();
        const month = control.value.getMonth();
        const year = control.value.getFullYear();

        if (day > 31 || month > 12 ){
            control.setErrors({ invalidDateFormat: true });
        }else{
            const birthdate = new Date(year, month - 1, day);
            // set error on matchingControl if validation fails
            if (birthdate > dateToCompare ){
                control.setErrors({ invalidAge: true });
            } else {
                control.setErrors(null);
            }
        }
    };
}
