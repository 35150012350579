import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageRadioGroupModel } from 'src/app/models/image-radio-group-model';

@Component({
  selector: 'image-radio-group',
  templateUrl: './image-radio-group.component.html',
  styleUrls: ['./image-radio-group.component.scss']
})
export class ImageRadioGroupComponent implements AfterViewInit {
  @Input() options!: ImageRadioGroupModel[];
  @Input() emitEvent: boolean = true;
  @Input() identifier: string = '';
  @Input() emitFunction: Function | undefined;
  @Output() valueChange = new EventEmitter<any>();
  selectedOption: ImageRadioGroupModel | undefined;

  changeValue(value: any) {
    if (this.emitEvent) {
      this.valueChange.emit(value);
    } else if (this.emitFunction) {
      this.emitFunction(value);
    }
  }

  ngAfterViewInit(): void {
    this.options.forEach(option => {
      if (option.selected) {
        this.selectedOption = option;
      }
    });
  }
}
