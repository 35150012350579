import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'app-confirm-phone',
  templateUrl: './confirm-phone.component.html',
  styleUrls: ['./confirm-phone.component.scss']
})
export class ConfirmPhoneComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  countryCode!: number;
  mobileNumber!: string;
  invalidCode!: boolean;
  step = 'init';
  showTooManyAttempts = false;
  faTimes = faTimes;

  // Timer related variables
  isDisabled: boolean = false;
  remainingTime: number = 0;
  private readonly TIME_LIMIT = 60 * 60 * 1000; // 60 minutes in milliseconds

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService) { }

  mobileForm = this.formBuilder.group({
    mobilePhoneNumber: ['', { validators: [Validators.required], updateOn: 'change' }],
    mobilePhonePrefix: ['', { validators: [Validators.required], updateOn: 'change' }]
  });

  mobileValidationForm = this.formBuilder.group({
    code: ['', { validators: [Validators.required], updateOn: 'change' }]
  });

  ngOnInit(): void {
    this.mobileForm.disable();
    this.userService.getUserInfo().subscribe((user) => {
      this.mobileForm.patchValue({
        mobilePhoneNumber: user.phoneNumber,
        mobilePhonePrefix: user.phoneCountryCode.toString()
      });
    });
    this.checkResendTimer(); // Initialize the timer check on component load
  }

  resendOTP(): void {
    if (!this.isDisabled) {
      const mobilePhonePrefix = parseInt(this.mobileForm.value.mobilePhonePrefix || '');
      const mobilePhoneNumber = this.mobileForm.value.mobilePhoneNumber || '';
      this.userService.sendPhoneConfirmationCode(mobilePhonePrefix, mobilePhoneNumber).subscribe(() => {
        this.startTimer();
      }, (err) => {
        this.showTooManyAttempts = true;
      });
    }
  }

  startTimer() {
    const now = new Date().getTime();
    localStorage.setItem('smsSentTimestamp', now.toString());

    this.isDisabled = true;
    this.remainingTime = 60; // 60 minutes

    const interval = setInterval(() => {
      this.remainingTime--;
      if (this.remainingTime <= 0) {
        clearInterval(interval);
        this.isDisabled = false;
        localStorage.removeItem('smsSentTimestamp');
      }
    }, 60000); // Update remaining time every minute
  }

  checkResendTimer() {
    const savedTimestamp = localStorage.getItem('smsSentTimestamp');

    if (savedTimestamp) {
      const savedTime = parseInt(savedTimestamp, 10);
      const now = new Date().getTime();
      const elapsed = now - savedTime;

      if (elapsed < this.TIME_LIMIT) {
        this.isDisabled = true;
        this.step = 'send-code';
        const remainingTimeInMs = this.TIME_LIMIT - elapsed;
        this.remainingTime = Math.ceil(remainingTimeInMs / 1000 / 60); // Remaining time in minutes
        const interval = setInterval(() => {
          this.remainingTime--;
        }, 60000);
        setTimeout(() => {
          clearInterval(interval);
          this.enableButton();
        }, remainingTimeInMs);
      } else {
        this.isDisabled = false;
      }
    }
  }

  enableButton() {
    this.isDisabled = false;
    localStorage.removeItem('smsSentTimestamp');
  }

  submitForm(): void {
    const mobilePhonePrefix = parseInt(this.mobileForm.value.mobilePhonePrefix || '');
    const mobilePhoneNumber = this.mobileForm.value.mobilePhoneNumber || '';
    this.userService.sendPhoneConfirmationCode(mobilePhonePrefix, mobilePhoneNumber).subscribe(() => {
      this.step = 'send-code';
    }, () => {
      this.showTooManyAttempts = true;
    });
  }

  finalise(): void {
    this.userService.updateUserInfo();
  }

  onOTPCodeCompleted(otpCode: any): void {
    this.mobileValidationForm.patchValue({
      code: otpCode
    });
  }

  submitCode(): void {
    const code = this.mobileValidationForm.value.code?.replace('9999', '99999');
    if (code) {
      this.userService.confirmPhone(code).subscribe(() => {
        localStorage.setItem('showPhoneNotification', 'true');
        this.userService.updateUserInfo();
      }, () => {
        this.invalidCode = true;
      });
    }
  }
}
