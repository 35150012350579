import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function ValidDNI(controlName: string): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        if (!control.value) {
            return;
        }
        let numero;
        let dnilet;
        let letra;
        let dni;
        const dniRegEx = /^[XYZ]?\d{5,8}[A-Z]$/;
        dni = control.value.toUpperCase();
        if (dniRegEx.test(dni) === true) {
            numero = dni.substr(0, dni.length - 1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            dnilet = dni.substr(dni.length - 1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero + 1);
            if (letra !== dnilet) {
                // Dni erroneo, la letra del NIF no se corresponde
                control.setErrors({ invalidFormat: true });
            } else {
                // Dni correcto
                control.setErrors(null);
            }
        } else {
            // Dni erroneo, formato no válido
            control.setErrors({ invalidFormat: true });
        }
    };
}
