import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from 'src/app/core/services/user.service';
import {faCheck, faCircle, faLocationDot, faPenToSquare, faTrash, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {PostalCode} from "../../../shared/validators/postal-code";

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit {
  @Input() property: any;
  @Input() canDelete: any;
  @Output() reloadEvent: EventEmitter<any> = new EventEmitter();
  editionMode = false;
  isMobile = window.innerWidth <= 770;
  addressRegex = new RegExp('^[a-zA-Z0-9À-ÿ\u00f1\u00d1 _\'().,\\-\\/]*$');
  addressForm = this.formBuilder.group(
    {
      streetName: [''
        ,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.addressRegex),
          ],
          updateOn: 'change',
        },
      ],
      streetNumber: [''
        ,
        { validators: [Validators.required], updateOn: 'change' },
      ],
      blockNumber: ['', { updateOn: 'change' }],
      floorNumber: ['', { updateOn: 'change' }],
      doorNumber: ['', { updateOn: 'change' }],
      city: ['', { validators: [Validators.required], updateOn: 'change' }],
      postalCode: ['', { validators: [Validators.required], updateOn: 'change' }],
      state: ['', { validators: [Validators.required], updateOn: 'change' }],
      country: ['', { validators: [Validators.required], updateOn: 'change' }],
    },
    {
      validator: PostalCode('postalCode', 'country'),
    }
  );
  faTrash = faTrash;
  faCircle = faCircle;
  address!: string;
  faLocationDot = faLocationDot;
  protected readonly faPenToSquare = faPenToSquare;
  protected readonly faXmark = faXmark;
  protected readonly faCheck = faCheck;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.setData();
    this.translate.onLangChange.subscribe((x: any) => this.setData());
  }

  deleteProperty(property: { index: string; }): void {
    this.userService.deleteProperty(property.index).subscribe((result) => {
      this.reloadEvent.emit();
    });
  }

  updateProperty(): void {
    const propertyUpdated = {
      // garden: this.addressForm.value.garden === 'true' ? true : false,
      // bedrooms: parseInt(this.addressForm.value.bedrooms),
      // pool: this.addressForm.value.pool === 'true' ? true : false,
      // size: parseFloat(this.addressForm.value.size),
      // type: parseInt(this.addressForm.value.propertyType),
      index: this.property.index,
      address: {
        address1: `${this.addressForm.value.streetName} ${this.addressForm.value.streetNumber}`,
        address2: `${this.addressForm.value.blockNumber ?? ''} ${this.addressForm.value.floorNumber ?? ''} ${this.addressForm.value.doorNumber ?? ''}`,
        city: this.addressForm.value.city,
        state: this.addressForm.value.state,
        countryCode: this.addressForm.value.country,
        postalCode: this.addressForm.value.postalCode,
        province: this.addressForm.value.state,
        nameOfStreet: this.addressForm.value.streetName,
        streetNumber: this.addressForm.value.streetNumber?.toString(),
        blockNumber: this.addressForm.value.blockNumber?.toString(),
        floorNumber: this.addressForm.value.floorNumber?.toString(),
        doorNumber: this.addressForm.value.doorNumber?.toString(),
      },
    };
    this.userService.updateProperty(propertyUpdated).subscribe((result) => {
      this.disableEdition();
      this.reloadEvent.emit();
    });
  }

  enableEdition(): void {
    this.addressForm.enable();
    this.editionMode = true;
  }

  disableEdition(): void {
    this.addressForm.disable();
    this.editionMode = false;
  }

  setData(): void {
    this.address = `${this.property.address.address1}, ${this.property.address.postalCode} ${this.property.address.city}`;
    this.addressForm.disable();
    this.addressForm.patchValue({
      streetName: this.property.address.nameOfStreet,
      streetNumber: this.property.address.streetNumber,
      blockNumber: this.property.address.blockNumber,
      floorNumber: this.property.address.floorNumber,
      doorNumber: this.property.address.doorNumber,
      country: this.property.address.countryCode,
      postalCode: this.property.address.postalCode,
      state: this.property.address.state,
      city: this.property.address.city,
    });
  }
}
