export class TransactionSender {
    name!: string;
    address!: string;
    country!: string;
    iban!: string;
    bic!: string;
    // tslint:disable-next-line:variable-name
    routing_code!: string;
    // tslint:disable-next-line:variable-name
    user_token!: string;
    // tslint:disable-next-line:variable-name
    ledger_token!: string;
}
