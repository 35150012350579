export class AppError {
    errorsDetails: AppErrorDetails[];

    constructor(errors: AppErrorDetails[]) {
        this.errorsDetails = errors;
    }
}

export class AppErrorDetails {
    code: string;
    description: string;

    constructor(errorCode: string, errorDescription: string) {
        if (errorCode !== 'INVALID_INPUT') {
            this.code = errorCode;
        } else {
            this.code = '';
        }
        this.description = errorDescription;
    }
}


export class InternalError extends AppError {
    constructor(errors: AppErrorDetails[] = []) {
        super(errors);
    }
}

export class ExternalSystemError extends AppError {
    constructor(errors: AppErrorDetails[] = []) {
        super(errors);
    }
}

export class BadInputError extends AppError {
    constructor(errors: AppErrorDetails[]) {
        super(errors);
    }
}

export class ConflictError extends AppError {
    constructor(errors: AppErrorDetails[]) {
        super(errors);
    }
}

export class NotFoundError extends AppError {
    constructor(errors: AppErrorDetails[]) {
        super(errors);
    }
}

export class AuthError extends AppError {
    constructor(errors: AppErrorDetails[]) {
        super(errors);
    }
}

export class UnknownError extends AppError {
    constructor(errors: AppErrorDetails[]) {
        super(errors);
    }
}
